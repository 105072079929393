import Section, {
    CardList,
    Column,
    PaddleDownload,
    PaddleFeatureIntro,
    PaddleTimeline,
    TabPaddle,
    TrackType,
    UseStep,
    WithComponent,
} from 'components/sections';
import {PaddleModalForm, usePaddleModalForm} from 'components/sections/paddleDownload/modalForm';

import Banner from 'components/banner';
import Layout from 'components/layouts';
import localData from 'locals/easydl/paddle';
import {useEffect} from 'preact/hooks';
import {useOfflineModal} from './offlineModal';

// eslint-disable-next-line @babel/new-cap
const SubSection = WithComponent(Section)({
    subSection: true,
});

export default function Paddle() {
    const {config, banner, overview, feature, step, scene, recommend, sectionColumn, Steps} = localData;
    const [visible, close, open] = usePaddleModalForm();

    useEffect(() => {
        const visibleChang = () => {
            open();
        };
        document.body.addEventListener('click', e => {
            if (e.target.classList.contains('js-paddle')) {
                visibleChang();
                e.stopPropagation();
                e.preventDefault();
            }
        });
    }, [open]);

    const offlineModalNode = useOfflineModal();

    return (
        <TrackType.Provider value={config}>
            <Layout>
                <Banner {...banner} />
                <Column sectionColumn={sectionColumn} />
                <SubSection title={overview.title} style={{paddingTop: '120px'}} id="paddle-intro">
                    <img className="pof-image" src={overview.image} alt={overview.title} />
                </SubSection>
                <SubSection title={feature.title} id="paddle-advantage">
                    <PaddleFeatureIntro data={feature.data} />
                </SubSection>
                <SubSection title={step.title} desc={step.desc} id="paddle-step">
                    <UseStep data={step.data} />
                </SubSection>
                <SubSection title={scene.title} id="paddle-scene">
                    <TabPaddle data={scene.data} />
                </SubSection>
                {/* <SubSection title={download.title} id={download.id}>
                    <PaddleDownload data={download.data} />
                </SubSection> */}
                <SubSection title={'产品动态'} id="paddle-steps">
                    <PaddleTimeline Steps={Steps} />
                </SubSection>
                <SubSection title={recommend.title} id="paddle-recommend">
                    <CardList {...recommend} />
                </SubSection>
                <PaddleModalForm visible={visible} onClose={close} />
                {offlineModalNode}
            </Layout>
        </TrackType.Provider>
    );
}
