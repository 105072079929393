/**
 * @file 判断环境
 * @author liuxinya@baidu.com
 */

const sandboxHostArr = [
    'qifu-sandbox.baidu.com',
    'cloudtest.baidu.com',
    'market.baidu-int.com',
    'wenxinworkshop-test.now.baidu.com',
    'qianfan-portal-sandbox.baidu-int.com'
];
const hostArr = [
    'qifu.baidu.com',
    'cloud.baidu.com',
    'market.baidu.com',
    'bce.baidu.com',
    'm.bce.baidu.com',
    'appbuilder.cloud.baidu.com',
    // 'wenxinworkshop-test.now.baidu.com',
    'qianfan.cloud.baidu.com'
];

export function getEnv() {
    if (typeof window !== 'undefined') {
        const host = location.host;
        return hostArr.indexOf(host) > -1 ? 'ONLINE' : host.indexOf('localhost') > -1 ? 'DEVELOPMENT' : 'SANDBOX';
    }
}

export function isSandBoxEnv() {
    return sandboxHostArr.indexOf(location.host) > -1;
}

export function isIE() {
    if (!!window.ActiveXObject || 'ActiveXObject' in window) {
        return true;
    }
    return false;
}

export function isWindowsSystem() {
    return /windows|win32/i.test(navigator.userAgent);
}

export function isPortal() {
    return /^cloud(test)?.baidu.com$/.test(location.host);
}

export function getHref() {
    if (typeof window !== 'undefined') {
        return location.href;
    }
}
