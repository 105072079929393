import {cloneElement} from 'preact';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useContext} from 'preact/hooks';
import {TrackType} from 'components/sections';
import {Trim} from 'utils';

import css from './index.less';
export function AButton(props) {
    const trackType = useContext(TrackType).trackType;
    const {
        children,
        href,
        type = 'default',
        size,
        plain = false,
        round = 'angle',
        className,
        target = '_blank',
        style,
        _offsetLeft,
        trackAction,
        trackTag,
        attrs,
        onClick,
        trackInfo = {}
    } = props;
    const clickHandle = (e) => {
        if (window._hmt && trackType && trackTag) {
            window._hmt.push(['_trackEvent', trackType, trackAction, Trim(trackTag)]);
        }
        if (href) {
            window.open(href, target);
        } else {
            e.preventDefault();
        }
        if (onClick) {
            onClick();
        }
    };
    return (
        <a
            class={classnames(
                css.abutton,
                className,
                css[`abutton-${type}`],
                size && css[`abutton-${size}`],
                round && css[`abutton-round-${round}`],
                {[css['is-plain']]: plain}
            )}
            style={{marginLeft: `${_offsetLeft}px`, ...style}}
            href="javascript:void(0);"
            native
            rel="noopener noreferrer"
            onClick={clickHandle}
            {...attrs}
            {...trackInfo}
        >
            {children}
        </a>
    );
}
AButton.prototype = {
    href: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['default', 'primary', 'default-primary']),
    // 分别对应高度 large 58px default 48px small 38px mini 34px
    size: PropTypes.oneOf(['large', 'small', 'mini']),
    plain: PropTypes.bool,
    // round: PropTypes.bool,
    round: PropTypes.oneOf(['angle', 'half', 'quarter']),
    className: PropTypes.string,
    _offsetLeft: PropTypes.number
};

export function AButtonGroup(props) {
    const {children, offset = 20, align = 'left'} = props;
    const child = () => {
        if (Array.isArray(children)) {
            return <>{children.map((ele, index) => cloneElement(ele, {_offsetLeft: !!index ? offset : 0}))}</>;
        } else if (children) {
            return children;
        }
        return null;
    };
    return <div class={classnames(css['abutton-grounp'], css[`abutton-grounp-${align}`])}>{child()}</div>;
}

AButton.prototype = {
    offset: PropTypes.number,
    align: PropTypes.oneOf(['left', 'center'])
};
AButton.defaultProps = {
    children: [],
    trackAction: '点击'
};
