import {Trim, genClassName} from 'utils';

import PropTypes from 'prop-types';
import {TrackType} from 'components/sections';
import {cloneElement} from 'preact';
import css from './index.less';
import {useContext} from 'preact/hooks';

const cls = genClassName(css);

export default function ALink(props) {
    const {children, className, trackAction, trackTag, href, onClick, ...params} = props;
    const trackType = useContext(TrackType).trackType;
    const clickHandle = (e) => {
        if (typeof onClick === 'function') {
            onClick();
        }
        if (window._hmt && trackType) {
            window._hmt.push(['_trackEvent', trackType, trackAction, Trim(trackTag)]);
        }
        if (!href) {
            e.preventDefault();
        }
    };

    return (
        <a
            {...params}
            className={cls('alink', className)}
            href={href}
            rel="noopener noreferrer"
            onClick={clickHandle}
            native
        >
            {children}
        </a>
    );
}

ALink.prototype = {
    href: PropTypes.string.isRequired,
    className: PropTypes.string,
    target: PropTypes.oneOf(['_self', '_blank'])
};
ALink.defaultProps = {
    target: '_blank',
    trackAction: '点击'
};

export function PreLink(enhance) {
    const preComponent = (props) => <ALink {...props} {...enhance} />;
    return preComponent;
}

export function ALinkGroup(props) {
    const {children, offset, align, style} = props;
    const child = () => {
        if (Array.isArray(children)) {
            return <>{children.map((ele, index) => cloneElement(ele, {_offsetLeft: index ? offset : 0}))}</>;
        } else if (children) {
            return children;
        }
        return null;
    };
    return (
        <div className={cls('alink-grounp', `alink-grounp-${align}`)} style={style}>
            {child()}
        </div>
    );
}

ALinkGroup.prototype = {
    offset: PropTypes.number,
    align: PropTypes.oneOf(['left', 'center'])
};
ALinkGroup.defaultProps = {
    offset: 20,
    children: [],
    align: 'left'
};
