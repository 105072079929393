import css from './index.less';
import ALink from 'components/base/aLink';

export function PhotoCards(props) {
    const {data, title} = props;
    if (!Array.isArray(data)) {
        return;
    }
    return data.map(({name, image, link, lists}) => (
        <div class={css['service-content-item']}>
            <div class={css['top-wrapper']}>
                <div class={css.title}>{name}</div>
                {/* <a class={css.understand} href={link}>
                    了解详情
                </a> */}
                <ALink className={css.understand} href={link} trackTag={`${title}_${name}`}>
                    了解详情
                </ALink>
            </div>
            <div class={css['content-wrapper']}>
                <div class={css.left}>
                    <img src={image} />
                </div>
                <div class={css.right}>
                    {lists.map(({title, desc}) => (
                        <div class={css['list-desc-item']}>
                            <div class={css.title}>{title}</div>
                            <p class={css.desc}>{desc}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ));
}
