/**
 * @file: enum.js
 * @Author: v_panfeng01@baidu.com
 * @Date: 2020-11-04 14:54:35
 * @Last Modified by: v_panfeng01
 * @Last Modified time: 2020-11-04 15:03:58
 */

export class EnumGroup {
    constructor(schema) {
        this.all = [];
        schema.forEach((it) => {
            const [codename, value, label] = it;
            this[codename] = {value, label};
            this.all.push({codename, value, label});
        });
    }

    label(value) {
        const theOne = this.all.find((it) => it.value === value);

        return theOne && theOne.label;
    }

    codename(value) {
        const theOne = this.all.find((it) => it.value === value);

        return theOne && theOne.codename;
    }

    value(codename) {
        const theOne = this.all.find((it) => it.codename === codename);
        return theOne && theOne.value;
    }
}
