export default {
    config: {
        trackType: 'EasyDL图像官网',
        layout: 'easydl',
        page: 'vision',
    },
    banner: {
        name: 'vis',
        title: 'EasyDL 图像',
        subTitle: '零算法基础定制高精度图像应用AI模型，<br/>提供端云多种灵活部署方案',
        bg: require('assets/subpage/vis-banner.jpg').default,
        buttonGroup: [
            {
                name: '立即使用',
                className: 'js-consoleModalBtn',
                size: 'small',
                type: 'primary',
                trackTag: '立即使用_官网上方',
                attrs: {
                    'data-track-action': 'click',
                    'data-track-category': 'EasyDL立即使用',
                    'data-track-name': 'EasyDL-产品页面-立即使用',
                    'data-track-value': '',
                },
            },
            {
                name: '使用文档',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/ok38n2w9h',
                size: 'small',
                plain: true,
                trackTag: '使用文档_官网上方',
            },
            {
                name: '产品定价',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/Xl2tvkkx8',
                size: 'small',
                plain: true,
                trackTag: '产品定价_官网上方',
            },
        ],
        linkGroup: [
            {
                name: '新手教程',
                href: 'https://console.bce.baidu.com/easydl/tutorial/model/objdct/models',
                size: 'small',
                plain: true,
                trackTag: '产品定价_新手教程',
                className: 'gold',
            },
        ],
    },
    modelTypes: {
        title: '源于实践的模型类型',
        data: [
            {
                title: '图像分类',
                desc: '定制识别一张图中是否是某类物体/状态/场景，适合图片中主体或者状态单一的场景',
                keywords: ['图片内容检索', '制造业分拣或质检'],
                img: require('assets/subpage/img-cls.png').default,
                link: {
                    name: '在线使用',
                    href: process.env.IMAGE_CONTROL,
                },
            },
            {
                title: '物体检测',
                desc: '定制识别图中每个物体的位置、名称，适合有多个主体、或要识别位置及数量的场景',
                keywords: ['视频监控', '工业检测', '医疗诊断'],
                img: require('assets/subpage/detection.png').default,
                link: {
                    name: '在线使用',
                    href: process.env.DETECTION,
                },
            },
            {
                title: '图像分割',
                desc: '对比物体检测，支持用多边形标注训练数据，模型可识别目标位置及轮廓（像素级）',
                keywords: ['卫星图像分析', '医学图像分析'],
                img: require('assets/subpage/segment.jpg').default,
                link: {
                    name: '在线使用',
                    href: process.env.SEGMENT,
                },
            },
        ],
    },

    feature: {
        title: '全面领先的功能效果',
        desc: '零门槛AI模型训练平台，为用户提供从智能安全的数据服务、高效便捷的模型定制到全面丰富的部署方式的一站式服务',
        data: [
            {
                title: '智能安全的数据服务',
                desc: '由EasyData提供数据在线采集、一键智能标注、过滤清洗、加工等一站式数据服务，助力开发者高效获取AI开发所需高质量数据',
                link: {
                    name: '了解更多',
                    href: 'https://ai.baidu.com/ai-doc/EASYDL/ok38n2w9h#数据支持',
                },
                banner: require('assets/subpage/vis-service.jpg').default,
            },
            {
                title: '高效便捷的模型定制',
                desc: '涵盖图像应用各类场景模型，由百度飞桨底层算法支持，支持可视化的模型效果评估，海量GPU资源免费分布式训练',
                link: {
                    name: '了解更多',
                    href: 'https://ai.baidu.com/ai-doc/EASYDL/ok38n2w9h#高精度效果',
                },
                banner: require('assets/subpage/vis-model.jpg').default,
            },
            {
                title: '全面丰富的部署方式',
                desc: '支持公有云API调用模型；由EasyEdge提供适应于市面上多种通用小型设备、本地服务器、百度自研硬件的离线部署以及端云协同部署方案',
                link: [
                    {
                        name: '了解更多',
                        href: 'https://ai.baidu.com/ai-doc/EASYDL/ok38n2w9h#丰富的部署方案',
                    },
                    {
                        name: '查看算法模型推理性能',
                        href: process.env.EASYDL_ADAPT,
                        class: 'text-link',
                    },
                ],
                banner: require('assets/subpage/vis-deploy.jpg').default,
            },
        ],
    },

    step: {
        title: '简单四步体验全流程，轻松获得AI模型',
        desc:
            // eslint-disable-next-line
            '一站式模型定制，全流程可视化操作，最快15分钟获得高精度AI模型，',
        data: [
            {
                name: '创建模型',
                desc: '了解不同类型模型的功能，创建想要训练的模型',
            },
            {
                name: '准备数据',
                desc: '上传并标注需要模型识别的示例数据',
            },
            {
                name: '训练模型',
                desc: '了解不同算法的优势，一键启动模型训练',
            },
            {
                name: '部署应用',
                desc: '将模型部署在云端、通用小型设备、本地服务器',
            },
        ],
    },

    scene: {
        title: '广泛成熟的实践应用，助力产业AI升级',
        data: [
            {
                name: '内容理解',
                desc: '将海量商品标签分类，实现商品识别分类、商品推荐等功能；制定图片审核规则，实现图片内容审核功能，常用于新闻、视频、青少年app等定制审核策略，过滤不良信息',
                banner: require('assets/subpage/vis-scene-filter.png').default,
                imgs: [
                    {
                        src: require('assets/easydl/logo/06.jpg').default,
                        href: `${process.env.CUSTOMER}/idcool`,
                    },
                    {
                        src: require('assets/easydl/logo/16.jpg').default,
                        href: `${process.env.CUSTOMER}/shihuo`,
                    },
                    {
                        src: require('assets/easydl/logo/03.jpg').default,
                        href: `${process.env.CUSTOMER}/keep`,
                    },
                ],
            },
            {
                name: '监控场景',
                desc: '生产现场的安全性监控，如工人的安全帽、工作服检测，物料堆放位置检测；公共场合及居住小区的可疑行为检测；超市防损监控等',
                banner: require('assets/subpage/vis-scene-monitor.jpg').default,
                imgs: [
                    {
                        src: require('assets/easydl/logo/01.jpg').default,
                        href: `${process.env.CUSTOMER}/nfdw`,
                    },
                    {
                        src: require('assets/easydl/logo/08.jpg').default,
                        href: `${process.env.CUSTOMER}/checkpoint`,
                    },
                ],
            },
            {
                name: '工业质检',
                desc: '生产流水线上针对产品的外观瑕疵检测，例如缺损、错装、漏装等情况的检测，提升检测效率，降低成本',
                banner: require('assets/subpage/vis-scene-inspection.jpg').default,
                imgs: [
                    {
                        src: require('assets/easydl/logo/05.jpg').default,
                        href: `${process.env.CUSTOMER}/liuzhouyuanchuang`,
                    },
                    {
                        src: require('assets/easydl/logo/14.jpg').default,
                        href: `${process.env.CUSTOMER}/aibao`,
                    },
                    {
                        src: require('assets/easydl/logo/13.jpg').default,
                        href: `${process.env.CUSTOMER}/xinqiao`,
                    },
                ],
            },
            {
                name: '农业医疗',
                desc: '根据医学检测图识别肿瘤、皮肤病、寄生虫的类型，辅助医生高效完成诊断；农作物生长成熟度判断、害虫检测预警等，节省务农人力，提升农作物产出',
                banner: require('assets/subpage/vis-scene-treatment.jpg').default,
                imgs: [
                    {
                        src: require('assets/easydl/logo/09.jpg').default,
                        href: `${process.env.CUSTOMER}/guangzhoukaihui`,
                    },
                    {
                        src: require('assets/easydl/logo/15.jpg').default,
                        href: `${process.env.CUSTOMER}/shengwuji`,
                    },
                ],
            },
        ],
    },

    recommend: {
        title: '相关推荐',
        data: [
            {
                name: 'BML 全功能AI开发平台',
                href: 'https://cloud.baidu.com/product/bml.html',
                desc: '企业级AI开发平台，一站式完成模型构建、训练、部署的全部工作',
            },
            {
                name: 'EasyDL零售行业版',
                href: 'https://ai.baidu.com/easydl/retail',
                desc: '专门用于定制货架合规性检查、自助结算、无人货柜等零售场景下识别商品的高精度AI模型，可以识别商品名称和在图中的位置',
            },
            {
                name: 'EasyMonitor视频监控开放平台',
                href: 'https://ai.baidu.com/solution/easymonitor',
                desc: '依托百度大脑的计算机视觉能力，针对视频监控场景，提供丰富的预置AI技能，零开发门槛快速搭建一套多路视频智能监控系统',
            },
        ],
    },

    start: {
        title: '开始定制EasyDL视觉模型',
        desc: '无需机器学习专业知识，全流程可视化便捷操作',
        buttonGroup: [
            {
                text: '立即使用',
                className: 'js-consoleModalBtn',
                type: 'primary',
                size: 'large',
                style: {
                    width: '140px',
                },
                trackTag: '立即开始_应用场景下方',
                attrs: {
                    'data-track-action': 'click',
                    'data-track-category': 'EasyDL立即使用',
                    'data-track-name': 'EasyDL-产品页面-立即使用',
                    'data-track-value': '',
                },
            },
        ],
    },

    // productForm: {
    //     title: '丰富多样的使用方式',
    //     data: [
    //         {
    //             name: 'onLine-easydl',
    //             title: '在线使用',
    //             desc: '在云端使用 EasyDL完成一站式模型训练及部署，即刻上手体验，无需依赖本地算力环境。云端产出的模型可灵活支持在线、离线多种部署方案',
    //             href: [
    //                 {
    //                     text: '立即使用',
    //                     href: ''
    //                 },
    //                 {
    //                     text: '使用文档',
    //                     href: '//ai.baidu.com/ai-doc/EASYDL/pk38n3odv'
    //                 }
    //             ]
    //         },
    //         // {
    //         //     name: 'local-easydl',
    //         //     title: '本地使用',
    //         //     desc: '在本地安装飞桨EasyDL-桌面版，可实现本地离线完成一站式模型训练及部署流程，数据与产出的模型可在本地高效管理',
    //         //     href: [{
    //         //         text: '立即使用',
    //         //         href: 'https://ai.baidu.com/easydl/paddle'
    //         //     }]
    //         // }
    //     ]
    // }
};
