import bytes from 'bytes';
import {useCallback, useEffect, useMemo, useState} from 'preact/hooks';
import {LOCALKEYS, getLocalStorageItem, genClassName, isMobile, send} from 'utils';
import YAML from 'yaml';
import css from './index.less';
import {MobileAlert, useMobileAlert} from './mobileAlert';
import {PaddleModalForm, usePaddleModalForm} from './modalForm';

const RELEASE_BASE_URL = process.env.PADDLE_DESKTOP_RELEASE;
const RELEASE_PLATFORMS = [
    {
        platform: 'windows',
        release: 'latest.yml',
        version: '1.0.0',
        releaseDate: 1629472894478,
        requirement: 'Windows7及以上，64位',
        formats: [
            {
                format: 'exe',
                size: 718903736,
                url: `${RELEASE_BASE_URL}/1.1.1/%E9%A3%9E%E6%A1%A8EasyDL-1.1.1.899.exe`
            }
        ]
    },
    {
        platform: 'macos',
        release: 'latest-mac.yml',
        version: '1.0.0',
        releaseDate: 1629472894478,
        requirement: 'macOS 10.11及以上，Intel CPU',
        formats: [
            {
                format: 'dmg',
                size: 746173108,
                url: `${RELEASE_BASE_URL}/1.1.1/%E9%A3%9E%E6%A1%A8EasyDL-1.1.1.899.dmg`
            }
        ]
    },
    {
        platform: 'linux',
        release: 'latest-linux.yml',
        version: '1.0.0',
        releaseDate: 1629472894478,
        requirement: 'Fedora 30及以上、Ubuntu 14.04及以上，64位',
        formats: [
            {
                format: 'rpm',
                size: 717856381,
                url: `${RELEASE_BASE_URL}/1.1.1/%E9%A3%9E%E6%A1%A8EasyDL-1.1.1.899.rpm`
            },
            {
                format: 'deb',
                size: 724317138,
                url: `${RELEASE_BASE_URL}/1.1.1/%E9%A3%9E%E6%A1%A8EasyDL-1.1.1.899.deb`
            }
        ]
    }
];

const cls = genClassName(css);

function formatSize(formats) {
    return formats.map(({size}) => bytes(size)).join(' / ');
}

function formatDate(date) {
    const d = new Date(date);
    return d.toLocaleDateString();
}

export function PaddleDownload({data}) {
    const {show: showAlert, hide: hideAlert, visible: alertVisible} = useMobileAlert();
    const [visible, onClose, onOpen] = usePaddleModalForm();
    // HACK 记录打开表单前的下载
    const [afterSubmit, setAfterSubmit] = useState();
    // 获取远程下载地址 FIXME: 这里有个时序问题要修复
    const [downloadUrl, setDownloadUrl] = useState(RELEASE_PLATFORMS);

    useEffect(() => {
        (async () => {
            const q = RELEASE_PLATFORMS.map(async (platform) => {
                try {
                    const response = await send({
                        baseURL: RELEASE_BASE_URL,
                        options: {
                            url: platform.release,
                            method: 'GET',
                            responseType: 'text'
                        }
                    });
                    const data = YAML.parse(response);
                    return {
                        platform,
                        data
                    };
                } catch (e) {
                    console.error(e);
                    return {
                        platform,
                        data: null
                    };
                }
            });
            const p = await Promise.all(q);
            for (const {platform, data} of await p) {
                setDownloadUrl((urls) =>
                    urls.map((url) => {
                        if (data && url.platform === platform.platform) {
                            return {
                                ...url,
                                version: data.version,
                                releaseDate: +new Date(data.releaseDate),
                                requirement: data.requirement || url.requirement,
                                formats: url.formats.map((format) => {
                                    const file = data.files.find((f) => f.url.endsWith(`.${format.format}`));
                                    if (file) {
                                        return {
                                            ...format,
                                            size: file.size,
                                            url: `${RELEASE_BASE_URL}/${file.url}`
                                        };
                                    }
                                    return format;
                                })
                            };
                        }
                        return url;
                    })
                );
            }
        })();
    }, []);

    const handleDownload = useCallback(
        (url) => {
            if (isMobile()) {
                showAlert();
                return;
            }

            const isCollected = Boolean(getLocalStorageItem(LOCALKEYS.PaddleInfo));
            const doDownload = () => {
                if (!url) {
                    console.error('No available links found.');
                    return;
                }
                window.open(url);
            };
            if (!isCollected) {
                setAfterSubmit(() => doDownload);
                onOpen();
            } else {
                setAfterSubmit();
                doDownload();
            }
        },
        [onOpen, showAlert]
    );

    const renderData = useMemo(
        () =>
            downloadUrl.reduce((m, c) => {
                m[c.platform] = {
                    size: formatSize(c.formats),
                    version: `V${c.version}`,
                    requirement: c.requirement,
                    releaseDate: formatDate(c.releaseDate)
                };
                return m;
            }, {}),
        [downloadUrl]
    );

    const getDownloadUrl = useCallback(
        (platform, format) =>
            downloadUrl.find((it) => it.platform === platform)?.formats.find((it) => it.format === format)?.url ?? null,
        [downloadUrl]
    );

    const downloadData = useMemo(
        () =>
            data?.map((plat) => ({
                ...plat,
                infos: plat.infos.map((info) => ({
                    key: info.key,
                    value: renderData[plat.platform][info.id]
                })),
                buttons: plat.buttons.map((button) => ({
                    name: button.name,
                    url: getDownloadUrl(plat.platform, button.format),
                    attrs: button.attrs
                }))
            })) ?? [],
        [data, getDownloadUrl, renderData]
    );

    return (
        <div className={cls('download')}>
            {downloadData.map(({platform, title, infos, buttons}) => (
                <div className={cls('download-item')} key={title}>
                    <div className={cls('header')}>
                        <div className={cls('icon')}>
                            <img src={require(`assets/paddle/${platform}.png`).default} alt={platform} />
                        </div>
                        <p className={cls('type')}>{title}</p>
                    </div>
                    <div className={cls('body')}>
                        {infos.map(({key, value}) => (
                            <div className={cls('body-info')} key={key}>
                                <span className={cls('body-info_key')}>{key}</span>
                                <span className={cls('body-info_value')}>{value}</span>
                            </div>
                        ))}
                    </div>
                    <div className={cls('footer')}>
                        {buttons.map(({name, url, attrs}) => (
                            <div
                                className={cls('download-btn')}
                                // eslint-disable-next-line
                                onClick={() => handleDownload(url)}
                                key={name}
                                {...attrs}
                            >
                                {name}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
            <PaddleModalForm visible={visible} onClose={onClose} afterSubmit={afterSubmit} />
            <MobileAlert visible={alertVisible} onClose={hideAlert} />
        </div>
    );
}

export * from './duty';
export * from './column';
