import {AButtonGroup, AButton} from 'components/base/aButton';
import ALink, {ALinkGroup} from 'components/base/aLink';
import css from './index.less';
import {innerHtml, genClassName} from 'utils';
import VideoPlayer, {useVideoPlayer} from 'components/videoPlayer';
const cls = genClassName(css);

export default function Banner(props) {
    const {name, bg, title, subTitle, buttonGroup, children, align = 'left', tip, video, linkGroup} = props;
    const {visible, open, close} = useVideoPlayer();
    return (
        <div className={cls('banner', `banner_${name}`)} style={{backgroundImage: `url(${bg})`}}>
            <div className={cls('banner-content')}>
                <h1 className={cls('banner_title')}>{title}</h1>
                {innerHtml(<p className={cls('banner_detail')} innerHtml={subTitle} />)}
                {/* {tip} */}
                {video &&
                    <div className={cls('banner_video')} onClick={open}>一分钟了解EasyDL &gt;</div>
                }
                <div className={cls('banner-button')} style={{textAlign: align}}>
                    <AButtonGroup offset={20} align={align}>
                        {buttonGroup.map(({name, ...prop}) => {
                            return <AButton {...prop} key={name}>{name}</AButton>;
                        })}
                    </AButtonGroup>
                    {linkGroup &&
                        <ALinkGroup offset={20} align={align} style={{marginLeft: '20px', lineHeight: '40px'}} >
                            {linkGroup.map(({name, ...prop}) => {
                                return <ALink {...prop} key={name}>{name}</ALink>;
                            })}
                        </ALinkGroup>
                    }
                </div>
            </div>
            {children}
            <VideoPlayer src={video} visible={visible} onClose={close} />
        </div>
    );
}
