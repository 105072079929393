import PropTypes from 'prop-types';
import classnames from 'classnames';
import {createContext} from 'preact';
import css from './index.less';
// import {innerHtml} from 'utils';

function Desc(props) {
    const {data, children, subSection} = props;
    if (!data) {
        return;
    }
    return (
        <div
            className={classnames(css['section-desc'], {
                [css['subSection-desc']]: subSection
            })}
        >
            {data}
            {children}
        </div>
    );
}
export default function Section(props) {
    const {
        bg,
        id,
        title,
        desc,
        children,
        style,
        sectionWidth,
        className = '',
        subSection = false,
        descChildren
    } = props;

    return (
        <div className={classnames(css['section-wrap'], css[`section-${bg}`], css[className])} style={style}>
            <section id={id} className={classnames(css.section, css[sectionWidth])}>
                <div
                    className={classnames(css['section-header'], {
                        [css['subSection-header']]: subSection
                    })}
                >
                    <div className={css['section-title']}>{title}</div>
                    <Desc data={desc} subSection={subSection}>
                        {descChildren}
                    </Desc>
                </div>
                {children}
            </section>
        </div>
    );
}

Section.prototype = {
    bg: PropTypes.string,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string,
    id: PropTypes.string,
    sectionWidth: PropTypes.string
};

Section.defaultProps = {
    bg: 'white',
    desc: '',
    id: '',
    sectionWidth: 'easydl'
};

export const WithComponent = (Component) => (enhance) => {
    const preComponent = (props) => <Component {...props} {...enhance} />;
    return preComponent;
};

export const TrackType = createContext(null);

export * from './cards';
export * from './photoCards';
export {TabCardFeature, TabCard, TabCardBox} from './tabCard';
export * from './startUse';
export * from './useStep';
export * from './highLightNumber';
export * from './usageProcess';
export * from './templateCards';
export * from './tableCase';
export * from './feature';
export * from './cooperativePartner';
export * from './tabCard/download';
export * from './productForm';
export * from './paddleDownload';
export * from './scene';
export * from './uploader';
export * from './paddleParadigm';
