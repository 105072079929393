export const industry = [
    {value: 1, label: '制造业'},
    {value: 2, label: '软件与信息技术服务'},
    {value: 3, label: '教育与培训'},
    {value: 4, label: '零售业'},
    {value: 5, label: '医疗卫生'},
    {value: 6, label: '政府及公共事务'},
    {value: 7, label: '农林畜牧'},
    {value: 8, label: '金融'},
    {value: 9, label: '交通运输'},
    {value: 10, label: '能源服务/开发及加工/公共事业'},
    {value: 11, label: '互联网'},
    {value: 12, label: '其他'}
];
export const industryCustom = [
    {value: 1, label: '教育培训'},
    {value: 2, label: '文化娱乐'},
    {value: 3, label: '视频直播'},
    {value: 4, label: '游戏'},
    {value: 5, label: '交通出行'},
    {value: 6, label: '电子商务'},
    {value: 7, label: '金融'},
    {value: 8, label: '零售'},
    {value: 9, label: '酒店旅游'},
    {value: 10, label: '企业服务'},
    {value: 11, label: '物流货运'},
    {value: 12, label: '生活服务'},
    {value: 13, label: '医疗健康'},
    {value: 14, label: '房产家装'},
    {value: 15, label: '商业地产'},
    {value: 16, label: '智能硬件'},
    {value: 17, label: '制造业'},
    {value: 18, label: '农业'},
    {value: 19, label: '法律政务'},
    {value: 20, label: '安防监控'},
    {value: 21, label: '软件工具'},
    {value: 22, label: '智能手机'},
    {value: 99, label: '其他'}
];
export const industryGravity = [
    {value: 1, label: '软件&技术服务商'},
    {value: 2, label: '工业制造'},
    {value: 3, label: '零售/电商'},
    {value: 4, label: '智慧城市'},
    {value: 5, label: '金融'},
    {value: 6, label: '能源电力'},
    {value: 7, label: '医疗'},
    {value: 8, label: '政府&科研机构'},
    {value: 9, label: '交通物流'},
    {value: 10, label: '电信及服务商'},
    {value: 11, label: '教育'},
    {value: 12, label: '农林牧渔'},
    {value: 13, label: '互联网'},
    {value: 14, label: '其他'}
];

export const ExpectedPrice = [
    {
        label: '1000元以下',
        value: '1000元以下'
    },
    {
        label: '1000-3000元',
        value: '1000-3000元'
    },
    {
        label: '3000-6000元',
        value: '3000-6000元'
    },
    {
        label: '6000元以上',
        value: '6000元以上'
    }
];

export const Delays = [
    {
        label: '200毫秒以内',
        value: '1'
    },
    {
        label: '200-500毫秒',
        value: '2'
    },
    {
        label: '500-1000毫秒',
        value: '3'
    },
    {
        label: '2秒内',
        value: '4'
    }
];

export const Quantitys = [
    {
        value: '10个以下',
        label: '10个以下'
    },
    {
        value: '10-100个',
        label: '10-100个'
    },
    {
        value: '100个以上',
        label: '100个以上'
    }
];

export const menuData = [
    {
        name: '基础服务',
        children: [
            {
                name: '语音技术',
                children: [
                    {
                        name: '语音识别',
                        link: '/tech/speech'
                    },
                    {
                        name: '语音合成',
                        link: '/tech/speech/tts'
                    },
                    {
                        name: '语音唤醒',
                        link: '/tech/speech/wake'
                    },
                    {
                        name: '智能呼叫中心',
                        link: '/tech/bicc'
                    }
                ]
            },
            {
                name: '图像技术',
                children: [
                    {
                        name: '文字识别',
                        link: '/tech/ocr'
                    },
                    {
                        name: '图像识别',
                        link: '/tech/imagerecognition'
                    },
                    {
                        name: '车辆分析',
                        link: '/tech/vehicle'
                    },
                    {
                        name: '图像审核',
                        link: '/tech/imagecensoring'
                    },
                    {
                        name: '图像处理',
                        link: '/tech/imageprocess'
                    },
                    {
                        name: '图像搜索',
                        link: '/tech/imagesearch'
                    }
                ]
            },
            {
                name: '人脸与人体识别',
                children: [
                    {
                        name: '人脸识别',
                        link: '/tech/face'
                    },
                    {
                        name: '人体分析',
                        link: '/tech/body'
                    }
                ]
            },
            {
                name: '视频技术',
                children: [
                    {
                        name: '视频内容分析',
                        link: '/tech/video/vca'
                    },
                    {
                        name: '视频封面选取',
                        link: '/tech/video/vcs'
                    },
                    {
                        name: '视频比对检索',
                        link: '/tech/video/vcc'
                    },
                    {
                        name: '视频内容审核',
                        link: '/tech/video/vcr'
                    }
                ]
            },
            {
                name: 'AR与VR',
                children: [
                    {
                        name: '增强现实',
                        link: '//ar.baidu.com/m/'
                    },
                    {
                        name: '虚拟现实',
                        link: '/tech/vr'
                    }
                ]
            },
            {
                name: '自然语言处理',
                children: [
                    {
                        name: '语言处理基础技术',
                        link: '/tech/nlp'
                    },
                    {
                        name: '文本审核',
                        link: '/tech/textcensoring'
                    },
                    {
                        name: '机器翻译',
                        link: 'http://fanyi-api.baidu.com/api/trans/product/index'
                    },
                    {
                        name: '智能写作',
                        link: '/tech/intelligentwriting'
                    }
                ]
            },
            {
                name: '数据智能',
                children: [
                    {
                        name: '大数据处理',
                        link: 'http://di.baidu.com/m/#products'
                    },
                    {
                        name: '大数据分析',
                        link: 'http://di.baidu.com/m/#products'
                    },
                    {
                        name: '统计与推荐',
                        link: 'http://di.baidu.com/m/#products'
                    },
                    {
                        name: '舆情分析',
                        link: 'http://di.baidu.com/m/#products'
                    },
                    {
                        name: '大数据风控',
                        link: 'http://di.baidu.com/m/#products'
                    },
                    {
                        name: '大数据营销',
                        link: 'http://di.baidu.com/m/#products'
                    },
                    {
                        name: '知识生产与理解',
                        link: 'http://di.baidu.com/m/#products'
                    }
                ]
            },
            {
                name: '知识图谱',
                children: [
                    {
                        name: '知识理解',
                        link: '/tech/cognitive'
                    },
                    {
                        name: '知识图谱Schema',
                        link: '/tech/kg/schema'
                    },
                    {
                        name: '图数据库BGraph',
                        link: '/tech/kg/bgraph'
                    }
                ]
            }
        ]
    },
    {
        name: '软硬一体产品',
        children: [
            {
                name: '机器人导航和视觉',
                link: '/solution//roboticvision'
            },
            {
                name: '服务机器人',
                link: '/solution/servicerobot'
            }
        ]
    },
    {
        name: '定制化训练平台',
        children: [
            {
                name: 'EasyDL定制化训练和服务平台',
                link: '/easydl'
            },
            {
                name: '自定义模板文字识别',
                link: '/event/iocr'
            },
            {
                name: '理解与交互技术UNIT',
                link: '/tech/unit'
            }
        ]
    },
    {
        name: '深度学习开放平台',
        children: [
            {
                name: 'PaddlePaddle深度学习框架',
                link: 'http://paddlepaddle.org/'
            },
            {
                name: '开放数据集',
                link: 'http://ai.baidu.com/broad'
            }
        ]
    },
    {
        name: '行业解决方案',
        children: [
            {
                name: '智能零售',
                link: '/industry/retail'
            },
            {
                name: '智能校园',
                link: '/industry/education'
            },
            {
                name: '地产物业',
                link: '/industry/estate'
            },
            {
                name: '企业服务',
                link: '/industry/information'
            },
            {
                name: '信息服务',
                link: '/industry/information'
            },
            {
                name: '智能工厂',
                link: '/industry/factory'
            },
            {
                name: '智能政务',
                link: '/industry/government'
            },
            {
                name: '智能硬件',
                link: '/industry/hardware'
            }
        ]
    },
    {
        name: '专项解决方案',
        children: [
            {
                name: '人脸核身',
                link: '/solution/faceprint'
            },
            {
                name: '人脸闸机',
                link: '/solution/facegate'
            },
            {
                name: '人脸会场签到',
                link: '/solution/facesignIn'
            },
            {
                name: '人脸会员识别',
                link: '/solution/faceidentify'
            },
            {
                name: '平安城市视频分析',
                link: '/solution/ivs'
            },
            {
                name: '智能电销',
                link: '/solution/itma'
            },
            {
                name: '内容审核',
                link: '/solution/censoring'
            },
            {
                name: ' 大数据基础套件（鲁班）',
                link: '//di.baidu.com/m/solution/luban'
            },
            {
                name: '知识图谱构建与应用',
                link: '/solution/kgaas'
            },
            {
                name: '智能分诊',
                link: '/solution/aifenzhen'
            },
            {
                name: '医学文本结构化',
                link: '/solution/mtp'
            },
            {
                name: '智能货柜',
                link: '/solution/cabinet'
            },
            {
                name: '自助结算台',
                link: '/solution/cashier'
            },
            {
                name: '工厂安全生产监控',
                link: '/solution/factory'
            },
            {
                name: '课堂专注度分析',
                link: '/solution/class'
            },
            {
                name: '语音搜索解决方案',
                link: '/solution/bmvs'
            }
        ]
    },
    {
        name: '客户案例',
        link: '/customer'
    },
    {
        name: '合作伙伴',
        children: [
            {
                name: '合作伙伴计划',
                link: '/partner'
            },
            {
                name: '解决方案类合作计划',
                link: '/partner/solution'
            },
            {
                name: '硬件集成类合作计划',
                link: '/partner/hardware'
            },
            {
                name: '课程培训类合作计划',
                link: '/partner/course'
            },
            {
                name: '数据服务类合作计划',
                link: '/partner/data'
            },
            {
                name: '查找合作伙伴',
                link: '/partner/case'
            }
        ]
    },
    {
        name: 'AI加速器',
        link: '/accelerator'
    },
    {
        name: '新闻动态',
        link: '/wise/newsList'
    }
];

// 合作伙伴
export const cooperativePartner = [
    {
        logo: require('assets/easydl/logo/01.jpg').default,
        name: '南方电网',
        desc: '检测输电线路安全隐患',
        href: `${process.env.CUSTOMER}/nfdw`
    },
    {
        logo: require('assets/easydl/logo/02.jpg').default,
        name: '太平洋汽车网',
        desc: '定制车型识别',
        href: `${process.env.CUSTOMER}/taipingyang`
    },
    {
        logo: require('assets/easydl/logo/03.jpg').default,
        name: 'Keep',
        desc: '定制奖牌识别模型',
        href: `${process.env.CUSTOMER}/keep`
    },
    {
        logo: require('assets/easydl/logo/04.jpg').default,
        name: '通鼎互联',
        desc: '实现全时安全监控',
        href: `${process.env.CUSTOMER}/tongdinghulian`
    },
    {
        logo: require('assets/easydl/logo/05.jpg').default,
        name: '柳州源创',
        desc: '实现工业质检',
        href: `${process.env.CUSTOMER}/liuzhouyuanchuang`
    },
    {
        logo: require('assets/easydl/logo/06.jpg').default,
        name: '家图网',
        desc: '实现房源图片自动分类',
        href: `${process.env.CUSTOMER}/idcool`
    },
    {
        logo: require('assets/easydl/logo/07.jpg').default,
        name: 'e店佳',
        desc: '实现货架陈列合规性审核',
        href: `${process.env.CUSTOMER}/hzhuihe`
    },
    {
        logo: require('assets/easydl/logo/08.jpg').default,
        name: 'Checkpoint',
        desc: '实现购物车状态监控',
        href: `${process.env.CUSTOMER}/checkpoint`
    },
    {
        logo: require('assets/easydl/logo/09.jpg').default,
        name: '广州凯惠',
        desc: '实现X线影像辅助诊断',
        href: `${process.env.CUSTOMER}/guangzhoukaihui`
    },
    {
        logo: require('assets/easydl/logo/10.jpg').default,
        name: '彦思科技',
        desc: '实现自动识别船舶运输状态',
        href: `${process.env.CUSTOMER}/yihangotms`
    },
    {
        logo: require('assets/easydl/logo/11.jpg').default,
        name: '杭州气象局',
        desc: '实现气象自动观测',
        href: `${process.env.CUSTOMER}/hzqxj`
    },
    {
        logo: require('assets/easydl/logo/12.jpg').default,
        name: '中国专利信息中心',
        desc: '实现商标分类',
        href: `${process.env.CUSTOMER}/zhuanlizhongxin`
    },
    {
        logo: require('assets/easydl/logo/13.jpg').default,
        name: '北京新桥',
        desc: '判断桥梁类型及桥梁瑕疵',
        href: `${process.env.CUSTOMER}/xinqiao`
    },
    {
        logo: require('assets/easydl/logo/14.jpg').default,
        name: '爱包花饰',
        desc: '检测箱包内遗落物品',
        href: `${process.env.CUSTOMER}/aibao`
    },
    {
        logo: require('assets/easydl/logo/15.jpg').default,
        name: '生物记',
        desc: '实现野生生物自动识别',
        href: `${process.env.CUSTOMER}/shengwuji`
    }
];

export const proPriceDetail = [
    // 公有云
    {
        title: '公有云API',
        desc: '适用于网络条件良好的场景',
        content: ['单接口累计1000次免费调用', '超出部分按月阶梯计费', '开通付费可获得更高QPS'],
        config: [
            {
                name: '图像分类API',
                priceValue: '1-4',
                unit: '元/千次'
            },
            {
                name: '物体检测API',
                priceValue: '2-4',
                unit: '元/千次'
            },
            {
                name: '图像分割API',
                priceValue: '5-8',
                unit: '元/千次'
            },
            {
                name: '文本分类API',
                priceValue: '1.5-2.5',
                unit: '元/千次'
            },
            {
                name: '声音分类API',
                priceValue: '1.5-4',
                unit: '元/千次'
            }
        ],
        tips: '视频分类价格请{{cooperate}}进行询价',
        btn: {
            title: '定价文档',
            href: 'https://ai.baidu.com/ai-doc/EASYDL/1k38n2w1t'
        }
    },
    // sdk
    {
        title: '设备端SDK',
        desc: '适用于设备端离线计算的场景',
        content: ['免费测试期3个月', '可按设备数/包名购买永久授权', '购买后支持免费更新模型'],
        config: [
            {
                name: '基础版SDK',
                priceValue: '200-300',
                unit: '元/设备'
            },
            {
                name: '加速版SDK',
                priceValue: '1000-2000',
                unit: '元/设备'
            }
        ],
        tip: '若离线SDK的应用场景为手机APP，无法按设备数购买授权，支持按产品线购买序列号，详细价格请参考{{cooperate}}',
        btn: {
            title: '定价文档',
            href: 'https://ai.baidu.com/ai-doc/EASYDL/8k38n2v77'
        }
    },
    // 私有服务器部署
    {
        title: '私有服务器部署',
        desc: '适用于已有私有服务器的场景',
        content: ['若模型已经训练完毕，需购买私有服务器部署方案，请{{cooperate}}进行询价'],
        btn: {
            title: '合作咨询',
            href: 'xxxxx'
        }
    },
    // 软硬一体方案
    {
        title: '软硬一体方案',
        desc: '适用于需同时购买软硬件的场景',
        content: ['目前EasyDL已推出两款软硬一体方案，可通过AI市场进行购买'],
        btn: {
            title: '了解详情',
            href: 'https://ai.baidu.com/easydl/solution'
        }
    }
];

export const retailPriceDetail = [
    {
        title: '定制商品检测API',
        desc: '单接口累计1000次免费调用，超出部分按照月调用量阶梯计费',
        params: [
            {k: '调用量', v: '7-9元/千次'},
            {k: '并发数', v: '4QPS'},
            {k: '售后服务响应', v: '7*24小时'}
        ],
        btn: {
            title: '价格文档',
            href: 'https://ai.baidu.com/ai-doc/EASYDL/zk38n2uk0'
        }
    },
    {
        title: '标准商品检测API',
        desc: '服务接口邀测中，请点击下方申请试用，提交业务需求',
        btn: {
            title: '申请试用',
            href: 'xxx'
        }
    },
    {
        title: '货架拼接',
        desc: '限时免费，免费拼接200次，如需更多额度，请提交合作咨询',
        btn: {
            title: '合作咨询',
            href: 'xxxxxx'
        }
    },
    {
        title: '私有服务器部署',
        desc: '适用于已有私有服务器的场景',
        content: ['定制商品检测支持私有服务器部署，若模型已经训练完毕，需要购买私有服务器部署方案，请合作咨询进行询价'],
        btn: {
            title: '合作咨询',
            href: 'xxxxxx'
        }
    }
];

export const serviceTypes = [
    {value: 1, label: '高级版购买'},
    {value: 3, label: '功能咨询'},
    {value: 4, label: '模型定制化服务'},
    {value: 5, label: '私有化平台部署'},
    {value: 6, label: '市场合作'},
    {value: 7, label: '离线SDK额度扩充'},
    {value: 8, label: '申请试用'}
];

export const customerTypes = [
    {key: 1, title: '企业'},
    {key: 2, title: '个人'}
];

export const sourceTypes = [
    {value: 0, label: '请选择'},
    {value: 1, label: '官网'},
    {value: 2, label: '活动'},
    {value: 3, label: '搜索引擎'},
    {value: 4, label: '他人推荐'},
    {value: 5, label: '其他'}
];

export const informLevels = [
    {key: 1, title: '不了解'},
    {key: 2, title: '仅了解'},
    {key: 3, title: '使用过'}
];
