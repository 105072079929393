import {useState, useEffect, useCallback} from 'preact/hooks';

export default function useModal(select) {
    const [visible, setVisible] = useState(false);
    const onClose = useCallback(() => {
        setVisible(false);
    }, []);
    useEffect(() => {
        const visibleHandle = (event) => {
            if (Array.from(document.querySelectorAll(select)).includes(event.target)) {
                setVisible((visible) => !visible);
            }
        };
        document.addEventListener('click', visibleHandle);
        return () => {
            document.removeEventListener('click', visibleHandle);
        };
    });
    return [visible, onClose];
}
