/*
 * @Author: your name
 * @Date: 2021-05-15 17:57:01
 * @LastEditTime: 2021-05-16 14:59:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /easy-landing/src/utils/style.js
 */
import classnames from 'classnames';

export const genClassName =
    (cssModule) =>
    (...classname) =>
        classnames(classname)
            .split(' ')
            .map((c) => cssModule[c] || c)
            .join(' ');
