import {innerHtml} from 'utils';
import style from './index.less';

export function ProductForm(props) {
    const {data} = props;
    return (
        <div className={style.productForm}>
            {data.map(({name, title, desc, href}) => (
                <div key={name} className={style.item}>
                    <div className={style.title}>{title}</div>
                    {innerHtml(<div className={style.desc} innerHtml={desc} />)}
                    <div className={style['link-wrap']}>
                        {href.map((one) => (
                            <a
                                key={one.href}
                                href={one.href || 'javascript:void(0);'}
                                className={one.href ? style.link : `${style.link} js-consoleModalBtn`}
                                native
                            >
                                {one.text}
                            </a>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}
