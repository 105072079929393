import {useState, useCallback, useRef, useEffect} from 'preact/hooks';
import classnames from 'classnames';
import css from './index.less';

export default function VideoPlayer(props) {
    const {visible, onClose, src, wrapperClass, close, onShow, onHidden, ...other} = props;
    const videoDom = useRef();

    useEffect(() => {
        if (visible) {
            document.body.classList.add(css['disable-scroll']);
            onShow?.();
        } else {
            document.body.classList.remove(css['disable-scroll']);
            onHidden?.();
        }
    }, [visible]);

    const closeHandle = () => {
        if (videoDom.current) {
            videoDom.current.pause();
        }

        onClose();
    };

    const stopHandle = (e) => {
        e.stopPropagation();
    };

    const onKeyDown = (e) => {
        if (e.keyCode !== 27 || e.key !== 'Escape') {
            return;
        }
        closeHandle();
    };

    return visible ? (
        <div
            className={classnames(['modal_wrap', css.wrapper, wrapperClass])}
            onClick={closeHandle}
            onKeyDown={onKeyDown}
        >
            <div className={classnames(['modal', css.modal])}>
                <div className={classnames(['modal-close', css['modal-close']])} onClick={closeHandle}>
                    {close || <i className="tzfont tzfont-close" />}
                </div>
                <div onClick={stopHandle}>
                    <video ref={videoDom} className={css.video} controls {...other}>
                        <source src={src} />
                    </video>
                </div>
            </div>
        </div>
    ) : null;
}

export function useVideoPlayer() {
    const [visible, setVisible] = useState(false);
    const open = useCallback(() => {
        setVisible(true);
    }, []);
    const close = useCallback(() => {
        setVisible(false);
    }, []);

    return {visible, open, close};
}
