import {cloneElement} from 'preact';
import {Children} from 'preact/compat';
import {useState} from 'preact/hooks';
import css from './index.less';
import classnames from 'classnames';

export function Field(props) {
    const {label, children, required = true, tip, radio = false, desc} = props;
    const labelClass = classnames(css['form-item'], {
        [css['form-item-required']]: required
    });
    return (
        <div className={labelClass}>
            <label className={css['form-item-label']}>{label}</label>
            <div className={css['form-item-content']}>
                <div className={!radio && css['form-item-children']}>{children}</div>
                {tip && <div className={css['form-item-tip']}>{tip}</div>}
                {desc && <div className={css['form-item-desc']}>{desc}</div>}
            </div>
        </div>
    );
}

export function Input(props) {
    const {tag = true, className, ...prop} = props;
    return tag ? (
        <input
            className={classnames(css['form-item-input'], className)}
            type="text"
            autoComplete="off"
            {...prop}
        />
    ) : (
        <textarea
            className={classnames(css['form-item-textarea'], className)}
            type="text"
            autoComplete="off"
            {...prop}
        />
    );
}

export function Submit(props) {
    const {name = '提交', onClick} = props;
    return (
        <div className={css.submit} onClick={onClick}>
            {name}
        </div>
    );
}

export function Radio(props) {
    const {name, radios, onChange, onBlur, value, disabled} = props;
    return radios.map(({key, title}) => (
        <label className={css['form-item-radio']} key={title}>
            <input
                type="radio"
                name={name}
                value={key}
                onChange={(e) => onChange(e)}
                onBlur={(e) => onBlur(e)}
                checked={+value === +key}
                disabled={disabled}
            />
            <span className={css['radio-item']}>{title}</span>
        </label>
    ));
}

const PLACEHOLDER_VALUE = 0;

export function Select(props) {
    const {name, selectData, onChange, onBlur, value, disabled, placeholder} = props;
    return (
        <select
            className={css['form-item-select']}
            name={name}
            value={value || PLACEHOLDER_VALUE}
            onChange={(e) => onChange?.(e)}
            onBlur={(e) => onBlur?.(e)}
            disabled={disabled}
        >
            {placeholder && <option key='placeholder' value={PLACEHOLDER_VALUE} disabled>{placeholder}</option>}
            {selectData.map((it) => (
                <option key={it.value} value={it.value}>
                    {it.label}
                </option>
            ))}
        </select>
    );
}

export function Text(props) {
    return <div className={css.text}>{props.text}</div>;
}

export function CodeImg() {
    const api = () => `${process.env.API_URL}?method=record/captcha&random=${Math.random()}`;
    const [verifyCode, setVerifyCode] = useState(api());

    return (
        <img
            alt="点击刷新"
            src={verifyCode}
            className={css['verify-code']}
            // eslint-disable-next-line
            onClick={() => setVerifyCode(api())}
        />
    );
}

export const Checkbox = (props) => {
    const {value, name, onChange, checked, label, bottom, right, disabled} = props;
    return (
        <label className={css['form-item-checkbox']}>
            <input
                className={css.checkbox}
                type="checkbox"
                name={name}
                value={value}
                onChange={onChange}
                checked={checked}
                disabled={disabled}
            />
            <div className={css['checkbox-label']}>
                {label}
                {right}
                <div>{bottom}</div>
            </div>
        </label>
    );
};

export const CheckboxGroup = (props) => {
    const {onChange, children, value: v, name} = props;
    const selected = v ?? [];
    const handleChange = (e) => {
        if (typeof onChange !== 'function') {
            return;
        }
        const value = e.target.value;
        let res;
        if (e.target.checked) {
            const hasValue = selected.includes(value);
            if (hasValue) {
                res = selected;
            } else {
                res = selected.concat([value]);
            }
        } else {
            res = selected.filter((item) => item !== value);
        }
        // 装作是 Event. Next time
        onChange({target: {name, value: res}});
    };
    const childrenWithProps = Children.map(children, (child) => {
        const {value} = child.props;
        return cloneElement(child, {checked: selected.includes(value), name, onChange: handleChange});
    });

    return <div className={css['checkbox-group']}>{childrenWithProps}</div>;
};
