import {genClassName, send} from 'utils';
import {useEffect, useState} from 'preact/hooks';

import DOMPurify from 'dompurify';
import css from './duty.less';
import {marked} from 'marked';

const cls = genClassName(css);

const LICENSE_URL = process.env.PADDLE_DESKTOP_LICENSE;

export function PaddleDuty() {
    const [html, setHtml] = useState('');
    useEffect(() => {
        (async () => {
            const response = await send({
                options: {
                    url: LICENSE_URL,
                    method: 'GET',
                    responseType: 'text'
                }
            });
            setHtml(DOMPurify.sanitize(marked(response)));
        })();
    }, []);
    return (
        // bca-disable-next-line
        <div className={cls('duty')} dangerouslySetInnerHTML={{__html: html}}>
            <h1>载入中</h1>
        </div>
    );
}
