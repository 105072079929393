/**
 * @file: constants.js
 * @Author: v_panfeng01@baidu.com
 * @Date: 2020-11-04 14:53:28
 * @Last Modified by: v_panfeng01
 * @Last Modified time: 2020-11-04 15:08:15
 */

import {EnumGroup} from './enum';

export const MODEL_TYPES = new EnumGroup([
    ['IMG_CLS', 1, '图像分类'],
    ['OBJ', 2, '物体检测'],
    ['SOUND_CLS', 3, '声音分类'],
    ['TEXT_CLS', 4, '文本分类'],
    ['VIDEO_CLS', 5, '视频分类'],
    ['SEGMENT', 6, '图像分割'],
    ['SENTIMENT', 9, '情感倾向分析'],
    ['TEXT_ENTITY_EXTRACT', 10, '文本实体抽取'],
    ['ML', 201, '表格数据预测'],
    ['RETAIL', 1001, '商品检测']
]);

export const TEMPLATE_TYPES = new EnumGroup([
    ['IMG_CLS', 100, '图像分类'],
    ['OBJ', 200, '物体检测'],
    ['SOUND_CLS', 300, '声音分类'],
    ['TEXT_CLS', 400, '文本分类-单标签'],
    ['TEXT_CLS_MULTI', 401, '文本分类-多标签'],
    ['VIDEO_CLS', 500, '视频分类'],
    ['INSTANCE_SEGMENT', 600, '图像分割'],
    ['SENTIMENT', 900, '情感倾向分析'],
    ['TEXT_ENTITY_EXTRACT', 1000, '文本实体抽取'],
    ['ML', 20100, '表格数据预测'],
    ['RETAIL', 10010, '定制商品检测']
]);

export const PRO_TYPES = new EnumGroup([
    ['SCRIPT', 100, '脚本调参'],
    ['NOTEBOOK', 200, 'Notebook']
]);
