import classnames from 'classnames';
import {AButtonGroup, AButton} from 'components/base/aButton';
import ALink from 'components/base/aLink';
import Section from 'components/sections';
import PropTypes from 'prop-types';

import css from './index.less';
import sections from '../index.less';

export function StartUse(props) {
    const {title, desc, buttonGroup} = props;

    return (
        <Section title={title} desc={desc}>
            <AButtonGroup align="center">
                {buttonGroup.map(({text, ...prop}) => (
                    <AButton key={text} {...prop}>
                        {text}
                    </AButton>
                ))}
            </AButtonGroup>
        </Section>
    );
}

StartUse.prototype = {
    title: PropTypes.string.isRequired,
    desc: PropTypes.string,
    button: PropTypes.string
};
StartUse.defaultProps = {
    buttonGroup: []
};

export function BMLStartUse(props) {
    const {title, desc, products, titleClassName, buttonGroup} = props;
    return (
        <section className={css['bml-start-use']}>
            <div className={css.left}>
                <div className={classnames(css.title, css[titleClassName])}>{title}</div>
                <p className={css.desc}>{desc}</p>

                <AButtonGroup className={css['button-group']} offset="26">
                    {buttonGroup.map(({text, ...prop}) => (
                        <AButton key={text} {...prop}>
                            {text}
                        </AButton>
                    ))}
                </AButtonGroup>
            </div>

            <div className={css.right}>
                <div className={css['sub-title']}>相关推荐</div>

                {products.map((p) => (
                    <div key={p.title} className={css['product-name']}>
                        <a href={p.url} rel="noreferrer" target="_blank">
                            <div className={css['product-name']}>{p.title}</div>
                        </a>
                        <p className={css['product-desc']}>{p.desc}</p>
                    </div>
                ))}
            </div>
        </section>
    );
}

export function SubStartUse(props) {
    const {buttonGroup} = props;
    return (
        <div className={css['sub-startUse-button']}>
            <AButtonGroup align="center">
                {buttonGroup.map(({text, ...prop}) => (
                    <AButton key={text} {...prop}>
                        {text}
                    </AButton>
                ))}
            </AButtonGroup>
        </div>
    );
}

export function StartAbout(props) {
    const {title, desc, buttonGroup, aboutLink, sectionWidth = 'easydl'} = props;
    return (
        <div className={classnames(sections['section-wrap'], css['start-use-related_shading'])}>
            <section className={classnames(sections.section, css['start-use-related_wrap'], sections[sectionWidth])}>
                <div className={css['start-use']}>
                    <div className={css['start-use_title']}>{title}</div>
                    <div className={css['start-use_desc']}>{desc}</div>
                    <AButtonGroup offset={20}>
                        {buttonGroup.map(({name, ...prop}) => (
                            <AButton
                                {...prop}
                                key={name}
                                style={{width: '140px', letterSpacing: '1px', fontWeight: 500}}
                            >
                                {name}
                            </AButton>
                        ))}
                    </AButtonGroup>
                </div>
                <div className={css.related}>
                    <div className={css.related_title}>相关推荐</div>
                    <div>
                        {aboutLink.map(({title, href, desc}) => (
                            <ALink className={css.related_item} href={href} key={title} trackTag={`相关推荐_${title}`}>
                                <div className={css.related_link}>
                                    {title} <i className="tzfont tzfont-arrow-right-2" />
                                </div>
                                <p className={css.related_desc}>{desc}</p>
                            </ALink>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}

export function EasyDLStartAbout(props) {
    const {title, desc, buttonGroup, aboutLink, sectionWidth = 'easydl'} = props;
    return (
        <div className={classnames(sections['section-wrap'], css['start-use-related_shading'])}>
            <section className={classnames(sections.section, css['start-use-related_wrap'], sections[sectionWidth])}>
                <div className={css['start-use']}>
                    <div className={css['start-use_easydl-title']}>{title}</div>
                    <div>
                        {desc.map((it) => (
                            <div key={it.text} className={css['start-use_descs']}>
                                <span className={css['start-use_descs_label']}>{it.label}</span>：<span>{it.text}</span>
                            </div>
                        ))}
                    </div>
                    <AButtonGroup offset={20}>
                        {buttonGroup.map(({name, ...prop}) => (
                            <AButton
                                {...prop}
                                key={name}
                                style={{width: '140px', letterSpacing: '1px', fontWeight: 500}}
                            >
                                {name}
                            </AButton>
                        ))}
                    </AButtonGroup>
                </div>
                <div className={css.related}>
                    <div className={css.related_title}>相关推荐</div>
                    <div>
                        {aboutLink.map(({title, href, desc}) => (
                            <ALink className={css.related_item} href={href} key={title} trackTag={`相关推荐_${title}`}>
                                <div className={css.related_link}>
                                    {title} <i className="tzfont tzfont-arrow-right-2" />
                                </div>
                                <p className={css.related_desc}>{desc}</p>
                            </ALink>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}
