import {useState} from 'preact/hooks';
import ALink from 'components/base/aLink';
import style from './index.less';

export function TableCase(props) {
    const [activeIndex, setActiveIndex] = useState(0);
    const {data} = props;
    const activeScene = data[activeIndex];
    return (
        <div className={style['application-scene']}>
            <ul className={style['application-scene-tab']}>
                {data.map((item, index) => {
                    return (
                        <li
                            key={item.name}
                            className={`${style['application-scene-tab-item']} ${style['js-wrapper-switch']} ${
                                index === activeIndex ? style.active : ''
                            }`}
                            onClick={() => setActiveIndex(index)}
                        >
                            {item.name}
                        </li>
                    );
                })}
            </ul>
            <div className={style['application-scene-content']}>
                <div className={`${style['application-scene-item']}`}>
                    <div className={style['top']}>
                        <div className={style['text-wrapper']}>
                            <span className={style['title']}>{activeScene.name}</span>
                            <p className={style['desc']}>{activeScene.desc}</p>
                        </div>
                        <div className={style['img-wrapper']}>
                            <img
                                src={activeScene.banner}
                                alt={activeScene.name}
                                className={style['application-scene-banner']}
                            />
                        </div>
                    </div>
                    <div className={style['bot']}>
                        <span className={style['title']}>合作案例</span>
                        <div className={style['user-logo-wrapper']}>
                            {activeScene.imgs ? (
                                activeScene.imgs.map((item) => (
                                    <ALink href={item.href} trackTag="应用场景_合作案例" key={item.name}>
                                        <img className={style['logo-img']} src={item.src} alt={item.name} />
                                    </ALink>
                                ))
                            ) : (
                                <div className={style['user-logo-wrapper']}>
                                    <span className={style['user-logo-look-forward']}>敬请期待</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export function TableCaseNew(props) {
    const [activeIndex, setActiveIndex] = useState(0);
    const {data} = props;
    const activeScene = data[activeIndex];
    return (
        <div className={`${style['application-scene']} ${style['application-new-scene']}`}>
            <ul className={style['application-scene-tab']}>
                {data.map((item, index) => {
                    return (
                        <li
                            key={item.name}
                            className={`${style['application-scene-tab-item']} ${style['js-wrapper-switch']} ${
                                index === activeIndex ? style.active : ''
                            }`}
                            onClick={() => setActiveIndex(index)}
                        >
                            {item.name}
                        </li>
                    );
                })}
            </ul>
            <div className={style['application-scene-content']}>
                <div className={`${style['application-scene-item']}`}>
                    <div className={style.top}>
                        <div className={style['text-wrapper']}>
                            <span className={style.title}>{activeScene.name}</span>
                            <p className={style.desc}>{activeScene.desc}</p>
                        </div>
                        <div className={style['img-wrapper']}>
                            <img
                                src={activeScene.banner}
                                alt={activeScene.name}
                                className={style['application-scene-banner']}
                            />
                        </div>
                    </div>
                    <div className={style.bot}>
                        <span className={style.title}>合作案例</span>
                        <div className={style['user-logo-wrapper']}>
                            {activeScene.imgs ? (
                                activeScene.imgs.map((item) => (
                                    <ALink href={item.href} trackTag="应用场景_合作案例" key={item.name}>
                                        <img className={style['logo-img']} src={item.src} alt={item.name} />
                                    </ALink>
                                ))
                            ) : (
                                <div className={style['user-logo-wrapper']}>
                                    <span className={style['user-logo-look-forward']}>敬请期待</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={style.practice}>
                        <span className={style.title}>场景范例</span>
                        <p className={style.desc}>
                            沉淀15+典型场景案例，覆盖工业、零售、泛互联网、安防监控等业务场景，为您提供完整的AI落地全流程指导，包括业务场景问题定位、解决方案设计、数据处理、模型训练
                        </p>
                        <br />
                        <ALink href='https://console.bce.baidu.com/easydl/scene' trackTag="应用场景_场景范例">
                            查看精选场景范例
                        </ALink>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function TabRetail({data}) {
    const [activeIndex, setActiveIndex] = useState(0);
    const activeScene = data[activeIndex];
    return (
        <div className={style['retail-func']}>
            <div className={style['retail-func-tab']}>
                {data.map((item, index) => {
                    return (
                        <div
                            key={item.name}
                            className={`${style['retail-func-tab-item']} ${
                                index === activeIndex ? style.active : ''
                            }`}
                            onClick={() => setActiveIndex(index)}
                        >
                            {item.title}
                        </div>
                    );
                })}
            </div>
            <div className={style['retail-func-content']}>
                <div className={style['retail-func-content-img']}>
                    <img src={activeScene.image} alt={activeScene.title} />
                </div>
                <div className={style['retail-func-content-info']}>
                    <p className={style['info-result']}>识别结果</p>
                    <div className={style['info-content']}>
                        {
                            activeScene.res.map(it => (
                                <div key={it} className={style['info-block']}>
                                    {it.map(([key, value], index) => (
                                        <div key={key} className={`${style[`info-${index}`]} ${style['info-single']}`}>
                                            <div className={style['info-key']}>{key}</div>
                                            <div className={style['info-value']}>{value}</div>
                                        </div>
                                    ))}
                                </div>
                            ))
                        }
                    </div>
                    <a
                        href={activeScene.doc}
                        className={style['info-doc']}
                        target="_blank"
                        rel="noreferrer"
                    >查看接口文档 &gt;</a>
                </div>
            </div>
        </div>
    );
}

export function TabPaddle({data}) {
    const [activeIndex, setActiveIndex] = useState(0);
    const activeScene = data[activeIndex];
    return (
        <div className={style['application-scene']}>
            <ul className={style['application-scene-tab']}>
                {data.map((item, index) => {
                    return (
                        <li
                            key={item.name}
                            className={`${style['application-scene-tab-item']} ${style['js-wrapper-switch']} ${
                                index === activeIndex ? style.active : ''
                            }`}
                            // eslint-disable-next-line
                            onClick={() => setActiveIndex(index)}
                        >
                            {item.name}
                        </li>
                    );
                })}
            </ul>
            <div className={style['application-scene-content']}>
                <div className={style['application-scene-item']}>
                    <div className={`${style.top} ${style['no-bottom-line']}`}>
                        <div className={style['text-wrapper']}>
                            <span className={style.title}>{activeScene.name}</span>
                            <p className={style.desc}>{activeScene.desc}</p>
                        </div>
                        <div className={style['img-wrapper']}>
                            <img
                                src={activeScene.banner}
                                alt={activeScene.name}
                                className={style['application-scene-banner']}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function TableCaseWx(props) {
    const [activeIndex, setActiveIndex] = useState(0);
    const {data} = props;
    const activeScene = data[activeIndex];
    return (
        <div className={`${style['application-scene']} ${style['application-wx-scene']}`}>
            <ul className={style['application-scene-tab']}>
                {data.map((item, index) => {
                    return (
                        <li
                            key={item.name}
                            className={`${style['application-scene-tab-item']} ${style['js-wrapper-switch']} ${
                                index === activeIndex ? style.active : ''
                            }`}
                            onClick={() => setActiveIndex(index)}
                        >
                            {item.name}
                        </li>
                    );
                })}
            </ul>
            <div className={style['application-scene-content']}>
                <div className={`${style['application-scene-item']}`}>
                    <div className={style.top}>
                        <div className={style['text-wrapper']}>
                            <span className={style.title}>{activeScene.name}</span>
                            <p className={style.desc}>{activeScene.desc}</p>
                        </div>
                        <div className={style['img-wrapper']}>
                            <img
                                src={activeScene.banner}
                                alt={activeScene.name}
                                className={style['application-scene-banner']}
                            />
                        </div>
                    </div>
                    <div className={`${style.bot}`}>
                        <span className={style.title}>合作案例</span>
                        <div className={style['user-logo-wrapper']}>
                            {activeScene.imgs ? (
                                activeScene.imgs.map((item) => (
                                    <ALink href={item.href} trackTag="应用场景_合作案例" key={item.name}>
                                        <img className={style['logo-img']} src={item.src} alt={item.name} />
                                    </ALink>
                                ))
                            ) : (
                                <div className={style['user-logo-wrapper']}>
                                    <span className={style['user-logo-look-forward']}>敬请期待</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

