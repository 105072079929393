/*
 * @Author: v_houlin02@baidu.com
 * @Date: 2020-11-25 11:28:34
 * @LastEditTime: 2021-06-08 15:43:54
 * @file: ocr.js
 */

const SOFTWARE_INFO = [
    {
        id: 'size',
        key: '应用大小：',
    },
    {
        id: 'version',
        key: '应用版本：',
    },
    {
        id: 'requirement',
        key: '系统要求：',
    },
    {
        id: 'releaseDate',
        key: '更新时间：',
    },
];

export default {
    config: {
        trackType: '飞桨EasyDL-桌面版',
        layout: 'paddle',
        page: 'paddle',
    },
    banner: {
        name: 'paddle',
        title: '飞桨EasyDL-桌面版',
        subTitle:
            '提供本地一站式模型训练与部署能力，支持零基础定制高精度模型，本地高效管理数据与模型，一键安装即可使用',
        bg: require('assets/subpage/paddle.jpg').default,
        buttonGroup: [
            // {
            //     name: '获取邀测资格',
            //     className: 'js-paddle',
            //     trackTag: '邀测_官网上方',
            //     size: 'small',
            //     type: 'primary'
            // }

            // {
            //     name: '立即下载',
            //     href: '#paddle-download',
            //     target: '_self',
            //     size: 'small',
            //     type: 'primary',
            //     trackTag: '立即下载_官网上方',
            //     attrs: {
            //         'data-track-action': 'click',
            //         'data-track-category': 'EasyDL在线使用',
            //         'data-track-name': 'EasyDL-产品页面-在线使用',
            //         'data-track-value': ''
            //     }
            // },
            {
                name: '合作咨询',
                href: '/easydl/workorder/',
                target: '_self',
                size: 'small',
                type: 'primary',
                trackTag: '合作咨询_官网上方',
            },
            {
                name: '使用文档',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/fl2swlu5b',
                size: 'small',
                plain: true,
                trackTag: '使用文档_官网上方',
            },
        ],
    },
    overview: {
        title: '本地一站式模型开发',
        image: require('assets/paddle/overview.png').default,
    },
    feature: {
        title: '领先的功能优势',
        data: [
            {
                title: '本地化',
                img: require('assets/paddle/feature1.png').default,
                desc: '训练数据无需上传，支持本地导入导出、高效管理，训练流程本地化，支持无网环境下进行模型生产',
            },
            {
                title: '轻量级',
                img: require('assets/paddle/feature2.png').default,
                desc: '无需繁琐部署流程，一键下载安装即可完成模型生产全流程，无缝连接设备本地数据及算力资源',
            },
            {
                title: '广兼容',
                img: require('assets/paddle/feature3.png').default,
                desc: '客户端兼容Windows、Mac、Linux操作系统，支持将训练完成的模型发布为离线SDK，最快五分钟即可完成业务集成',
            },
            {
                title: '高精度',
                img: require('assets/paddle/feature4.png').default,
                desc: '内置丰富的行业算法，可根据实际应用场景灵活选择，训练流程可视，无需代码基础即可完成高精度模型生产',
            },
        ],
    },
    step: {
        title: '操作流程简单，轻松获得AI模型',
        desc: '本地一站式模型开发，全流程可视化操作，最快15分钟获得高精度AI模型',
        data: [
            {name: '下载安装', desc: '根据设备操作系统下载并安装飞桨EasyDL至本地'},
            {name: '数据处理', desc: '导入本地数据，通过内置的标注工具完成数据标注'},
            {name: '模型训练', desc: '根据业务场景选择训练算法，进行本地模型训练'},
            {name: '部署应用', desc: '模型训练完毕后，导出模型SDK集成于业务系统中'},
        ],
    },
    scene: {
        title: '广泛的应用场景',
        data: [
            {
                name: '工业生产',
                desc: '在工业质检、生产安全等业务场景中，常需在无网环境下完成训练与部署，使用飞桨EasyDL可解决无网训练的痛点，通过本地设备无需联网即可完成',
                banner: require('assets/paddle/scenes1.png').default,
            },
            {
                name: '能源巡检',
                desc: '在为电力、石油等大型能源企业提供巡检方案时，常面临数据保密的行业要求，使用飞桨EasyDL进行一站式开发，数据无需上云，满足企业安全性要求',
                banner: require('assets/paddle/scenes2.png').default,
            },
            {
                name: '教学科研',
                desc: '在AI教学领域，飞桨EasyDL-桌面版作为辅助教学平台工具，可完成轻量级部署，无需运维与部署人力，下载安装即可使用',
                banner: require('assets/paddle/scenes3.png').default,
            },
            {
                name: '软件开发',
                desc: '软件服务商在为客户提供AI解决方案时，客户常提出数据保密的业务要求，使用飞桨EasyDL进行本地训练，无需数据上云，即可高效完成业务落地',
                banner: require('assets/paddle/scenes4.png').default,
            },
        ],
    },
    // download: {
    //     title: '立即体验飞桨EasyDL',
    //     id: 'paddle-download',
    //     data: [
    //         {
    //             platform: 'windows',
    //             title: 'Windows版',
    //             infos: SOFTWARE_INFO,
    //             buttons: [
    //                 {
    //                     name: '下载Windows版',
    //                     format: 'exe',
    //                     attrs: {
    //                         'data-track-action': 'click',
    //                         'data-track-category': '飞桨EasyDL桌面版官网-客户端下载（Windows版本）',
    //                         'data-track-name': '下载客户端',
    //                     }
    //                 }
    //             ]
    //         },
    //         {
    //             platform: 'macos',
    //             title: 'macOS版',
    //             infos: SOFTWARE_INFO,
    //             buttons: [
    //                 {
    //                     name: '下载macOS版',
    //                     format: 'dmg',
    //                     attrs: {
    //                         'data-track-action': 'click',
    //                         'data-track-category': '飞桨EasyDL桌面版官网-客户端下载（mac版本）',
    //                         'data-track-name': '下载客户端',
    //                     }
    //                 }
    //             ]
    //         },
    //         {
    //             platform: 'linux',
    //             title: 'Linux版',
    //             infos: SOFTWARE_INFO,
    //             buttons: [
    //                 {
    //                     name: '下载Linux版(rpm格式)',
    //                     format: 'rpm',
    //                     attrs: {
    //                         'data-track-action': 'click',
    //                         'data-track-category': '飞桨EasyDL桌面版官网-客户端下载（Linux rpm版本）',
    //                         'data-track-name': '下载客户端',
    //                     }
    //                 },
    //                 {
    //                     name: '下载Linux版(deb格式)',
    //                     format: 'deb',
    //                     attrs: {
    //                         'data-track-action': 'click',
    //                         'data-track-category': '飞桨EasyDL桌面版官网-客户端下载（Linux deb版本）',
    //                         'data-track-name': '下载客户端',
    //                     }
    //                 }
    //             ]
    //         }
    //     ]
    // },
    sectionColumn: [
        {
            id: 'intro',
            name: '功能介绍',
        },
        {
            id: 'advantage',
            name: '产品优势',
        },
        {
            id: 'scene',
            name: '应用场景',
        },
        // {
        //     id: 'download',
        //     name: '下载使用'
        // },
        {
            id: 'steps',
            name: '产品动态',
        },
        {
            id: 'recommend',
            name: '相关推荐',
        },
    ],
    Steps: [
        {
            date: '2021年9月',
            edition: '1.0',
            desc: '正式公测，提供图像分类、物体检测的零门槛AI开发流程构建功能，包括数据处理、模型训练、服务部署等。 ',
        },
        {
            date: '2021年12月',
            edition: '1.1',
            desc: [
                '新增图像分割的零门槛AI开发流程构建功能。',
                '新增Notebook开发方式，支持用户使用飞桨套件进行精度调优。',
                '针对图像场景整体新增高级调参建模方式，通过提供多种算法与网络组合，支持灵活搭配，实现模型快速调优。',
            ],
        },
        {
            date: '2022年3月',
            edition: '1.2',
            desc: ['新增案例中心，为用户提供更丰富的产业落地指导。', '新增问题反馈机制，优化用户问题及时响应解决机制。'],
        },
        {
            date: '2022年5月',
            edition: '2.0',
            desc: [
                '新增高性能算法，进一步降低模型计算资源、提高推理速度。',
                '支持训练完毕的高级调参任务在Notebook环境中打开，完成进一步细节调优，实现多种开发方式灵活切换，提高开发效率。',
                '支持增量训练，进一步降低用户训练的开发成本，提高开发效率。',
            ],
        },
    ],

    recommend: {
        title: '相关推荐',
        data: [
            {
                name: 'EasyDL零门槛开发平台',
                href: '/easydl',
                desc: '云端使用EasyDL完成一站式模型训练及部署，产出模型可灵活支持在线、离线多种部署方案',
            },
            {
                name: '飞桨PaddleX',
                href: 'https://github.com/paddlepaddle/paddlex',
                desc: '飞桨全流程开发工具，集飞桨核心框架、模型库、工具及组件等深度学习开发所需全部能力于一身',
            },
            {
                name: 'BML全功能AI开发平台',
                href: '/bml',
                desc: '企业级AI开发平台，一站式完成模型构建、训练、部署的全部工作',
            },
        ],
    },
};
