import css from './index.less';
import ALink from 'components/base/aLink';
import {useMemo, useState} from 'preact/hooks';
import classnames from 'classnames';
import {innerHtml} from 'utils';
const downloadImg = require('assets/bml/codelab/download.png').default;
const supportImg = require('assets/bml/codelab/support.png').default;
const notSupportImg = require('assets/bml/codelab/notsupport.png').default;

export function Download(props) {
    const {data} = props;
    const [curTab, setCurTab] = useState(0);
    const curItem = useMemo(() => data[curTab], [curTab, data]);

    const VersionInfo = (props) => {
        const {desc, download, info} = props;
        const [curVersion, setCurVersion] = useState(download[0]);
        const [showDrop, setDrop] = useState(false);
        const trackTag = curVersion.desc ? `${curItem.title}_${curVersion.desc}` : curItem.title;

        return (
            <>
                <div className={css['codelab_right-support']}>
                    {
                        desc.filter(it => it.version.includes(curVersion.version)).map(({support, info}) => (
                            <div className={css['support-item']} key={info}>
                                <dic className={css['support-img']}>
                                    <img src={support ? supportImg : notSupportImg} />
                                </dic>
                                {innerHtml(<div className={css['support-info']} innerHtml={info} />)}
                            </div>
                        ))
                    }
                    {curItem.title === 'Docker镜像版' &&
                        <a className={css.email} href="mailto:bml-codelab@baidu.com">企业集成请联系bml-codelab@baidu.com</a>
                    }
                </div>
                <div className={css['codelab_right-info']}>
                    {
                        info.map(({key, value}) => (
                            <div className={css['info-item']} key={value}>
                                <dic className={css['info-key']}>{key}：</dic>
                                <div className={css['info-value']}>{value}</div>
                            </div>
                        ))
                    }
                </div>
                <div className={css['codelab_right-download']}>
                    <ALink
                        href={curVersion.linkHref}
                        class={css['download-btn']}
                        trackTag={trackTag}
                    >立即下载{curVersion.desc}</ALink>
                    <div
                        className={classnames(css['download-select'], {
                            [css.hide]: download.length > 1
                        })}
                        onClick={() => setDrop(!showDrop)}
                    >
                        <div
                            className={classnames(css.triangle, {
                                [css.rotate]: showDrop
                            })}
                        />
                        <div
                            className={classnames(css['select-container'], {
                                [css.hide]: showDrop
                            })}
                        >
                            {
                                download.map((it, index) => (
                                    <div
                                        className={classnames(css['select-item'], {
                                            [css.active]: curVersion === it
                                        })}
                                        onClick={() => setCurVersion(download[index])}
                                        key={it.desc}
                                    >{it.desc}</div>
                                ))
                            }
                        </div>
                    </div>
                    {/* <a className={css['more-version']} href={moreVersionIntro} target="_blank" rel="noopener noreferrer" >更多版本介绍</a> */}
                </div>
            </>
        );
    };

    return (
        <>
            <div className={css.tech_tab}>
                {data.map((it, index) => (
                    <div
                        className={classnames(css.tech_item_tab, curTab === index && css.active)}
                        onClick={() => setCurTab(index)}
                        key={it.title}
                    >
                        {it.title}
                    </div>
                ))}
            </div>
            <div className={css.codelab_item}>
                <div className={css.codelab_left}>
                    <img src={downloadImg} />
                </div>
                <div className={css.codelab_right}>
                    <span className={css['codelab_right-title']}>
                        {curItem.title}
                        {curItem.subTitle && <span className={css['codelab_right-sub-title']}>{curItem.subTitle}</span>}
                    </span>
                    <VersionInfo {...curItem} />
                </div>
            </div>
        </>
    );
}