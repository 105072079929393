/**
 * @file: rule
 * @Author: v_panfeng01@baidu.com
 * @Date: 2020-11-10 16:07:56
 * @Last Modified by: v_panfeng01
 * @Last Modified time: 2020-11-10 16:29:56
 */

export const baseRules = {
    // techType: [{required: false}],
    // techService: [{required: false}],
    industry: [{required: true, message: '请选择您的行业'}],
    website: [{required: false}, {max: 120, message: '网址描述不能超过120个字'}],
    introduction: [{required: false}, {max: 500, message: '网址描述不能超过500个字'}],
    email: [
        {required: false},
        {pattern: /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/, message: '邮箱格式错误'}
    ],
    phone: [
        {required: true, message: '请输入联系人电话'},
        {pattern: /^1[0-9]{10}$/, message: '请输入正确的手机号'}
    ],

    description: [
        {required: true, message: '请描述您对AI的具体需求'},
        {max: 500, message: '描述不能超过500个字'}
    ],
    customerType: [{required: true, message: '选择客户类型'}],
    customerName: [
        {required: true, message: '请输入业务联系人'},
        {max: 20, message: '业务联系人名称不能超过20个字'}
    ],
    verifyCode: [
        {required: true, message: '请输入验证码'},
        {pattern: /^\w{4}$/, message: '验证码格式错误'}
    ]
};

const paddlepaddleRuler = {
    scene: [
        {required: true, message: '请填写本地化应用场景'},
        {max: 500, message: '描述不能超过500个字'}
    ],
    purpose: [{required: true, message: '请填写您训练模型的主要用途'}],
    classification: [{required: true, message: '请填写您常用到的模型类型'}],
    environment: [{required: true, message: '请填写在实际应用中您的模型通常部署在何种环境下'}]
};
export const cooperationRules = (compony = true, paddlepaddle = false) => {
    const rules = {...baseRules};
    if (compony) {
        Object.assign(rules, {
            company: [
                {required: true, message: '请输入公司名称'},
                {max: 20, message: '公司名称不能超过20个字'}
            ]
        });
    }
    if (paddlepaddle) {
        Object.assign(rules, paddlepaddleRuler);
    }
    return rules;
};

export const RulesMap = {
    industry: [
        {required: true, message: '请选择您的行业'},
        {pattern: /^[1-9]/, message: '请选择您的行业'}
    ],
    website: [{required: false}, {max: 120, message: '网址描述不能超过120个字'}],
    introduction: [{required: false}, {max: 500, message: '网址描述不能超过500个字'}],
    email: [
        {required: false},
        {pattern: /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/, message: '邮箱格式错误'}
    ],
    imageFile: [{required: false}],
    phone: [
        {required: true, message: '请输入联系人电话'},
        {pattern: /^1[0-9]{10}$/, message: '请输入正确的手机号'}
    ],
    customerType: [{required: true, message: '选择客户类型'}],
    customerName: [
        {required: true, message: '请输入业务联系人'},
        {max: 20, message: '业务联系人名称不能超过20个字'}
    ],
    verifyCode: [
        {required: true, message: '请输入验证码'},
        {pattern: /^\w{4}$/, message: '验证码格式错误'}
    ],
    scene: [
        {required: true, message: '请填写本地化应用场景'},
        {max: 500, message: '描述不能超过500个字'}
    ],
    purpose: [{required: true, message: '请填写您训练模型的主要用途'}],
    classification: [{required: true, message: '请填写您常用到的模型类型'}],
    environment: [{required: true, message: '请填写在实际应用中您的模型通常部署在何种环境下'}],
    company: [
        {required: true, message: '请输入公司名称'},
        {max: 20, message: '公司名称不能超过20个字'}
    ],
    name: [
        {required: true, message: '请输入昵称'},
        {max: 10, message: '公司名称不能超过10个字'}
    ],
    scenes: [
        {required: true, message: '请填写业务场景'},
        {min: 5, message: '网址描述不能低于5个字'},
        {max: 500, message: '业务场景不能超过500个字'}
    ],
    demand: [
        {required: true, message: '请填写您的需求'},
        {min: 5, message: '网址描述不能低于5个字'},
        {max: 500, message: '网址描述不能超过500个字'}
    ],
    companyScale: [{required: true, message: '请填写业务场景'}],
    description: [{required: false}],
    serviceType: [{required: true, message: '请填写您的描述'}],
    sourceType: [
        {required: true, message: '请填写来源类型'},
        {pattern: /^[1-9]/, message: '请选择您的行业'}
    ],
    informLevel: [{required: true, message: '请填写了解程度'}],
    serialNumber: [{required: true, message: '请选择序列号'}],
    applyReason: [{required: true, message: '请选择申请原因'}]
};

export const $useRules = (rules) => {
    if (Array.isArray(rules)) {
        return rules.reduce((pre, rule) => {
            pre[rule] = RulesMap[rule];
            return pre;
        }, {});
    }
    return {};
};
