import AsyncValidator from 'async-validator';
import { errorMsgArr } from 'locals/easydl/cooperation';
import { useCallback, useMemo, useState } from 'preact/hooks';
import Toast from 'components/gravity/toast';
import { setLocalStorageItem, LOCALKEYS, genClassName, $useRules, send } from 'utils';
import {
    industry,
    customerTypes,
    sourceTypes,
    informLevels
} from 'locals/easydl/common';
import { CodeImg, Field, Input, Select, Radio } from '../../base/field';
import Modal from '../../base/modal';
import css from './modalForm.less';

const cls = genClassName(css);

export function PaddleModalForm(props) {
    const { visible, onClose, afterSubmit } = props;
    const [formData, setFormData] = useState({
        customerType: 1,
        informLevel: 1,
        sourceType: 0,
        company: null,
        phone: null,
        industry: 0,
        scenes: null,
        verifyCode: null,
        name: null
    });
    const [formTips, setFormTips] = useState({
        customerType: null,
        informLevel: null,
        sourceType: null,
        company: null,
        phone: null,
        industry: null,
        scenes: null,
        verifyCode: null,
        name: null
    });

    const handleError = (err) => {
        const errObj = err.reduce(
            (pre, { message, field }) => ({
                ...pre,
                [field]: message
            }),
            {}
        );
        setFormTips({ ...formTips, ...errObj });
    };
    const [isShowCompany, setShowCompany] = useState(true);

    // 根据表单每个是否显示的子项来决定rule规则
    const isShow = useMemo(() => ({
        company: isShowCompany,
    }), [isShowCompany]);
    const rules = useMemo(
        () => {
            const targetRules = Object
                .keys(formData)
                .filter(it => (Reflect.has(isShow, it) ? isShow[it] : true));
            return $useRules(targetRules);
        }, [isShow, formData]
    );

    const handleChange = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        // 处理客户类型
        if (field === 'customerType') {
            setShowCompany(+value === 1);
        }
        setFormData({
            ...formData,
            [field]: value
        });
    };

    const handleBlur = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        const validator = new AsyncValidator({
            [field]: rules[field]
        });
        setFormTips({
            ...formTips,
            [field]: null
        });
        validator.validate({ [field]: value }).catch(({ errors }) => {
            errors && handleError(errors);
        });
    };

    const formSubmit = () => {
        const theIndustry = industry.find(({value}) => value === +formData.industry);
        const data = {...formData, industry: theIndustry.label};
        const validator = new AsyncValidator(rules);
        validator
            .validate(formData)
            .then(() => {
                send({
                    baseURL: process.env.API_URL,
                    method: 'paddleDesktop/support/customer/save',
                    payload: data
                })
                    .then(({ success, result, message }) => {
                        if (success) {
                            if (result) {
                                setLocalStorageItem(LOCALKEYS.PaddleInfo, true);
                            }
                            onClose();
                            afterSubmit && afterSubmit();
                            return;
                        }
                        // global error handler
                        if (message.global) {
                            Toast.error(message.global, 2000);
                            return;
                        }

                        const errMsg = errorMsgArr.find((it) => it.field === Object.entries(message.field)[0][0]);
                        handleError([errMsg]);
                    })
                    .catch((err) => console.error(err));
            })
            .catch(({ errors }) => handleError(errors));
    };
    return (
        <Modal visible={visible} onClose={onClose} title={'个人信息填写'}>
            <div className={cls('collect')}>
                <p className={cls('info')}>为了更好的为您提供服务，烦请您填写个人基本信息，感谢您的支持与配合</p>
                <Field label="用户类型" tip={formTips.customerType} radio>
                    <Radio
                        name="customerType"
                        // eslint-disable-next-line
                        onChange={handleChange}
                        // eslint-disable-next-line
                        onBlur={handleBlur}
                        value={formData.customerType}
                        radios={customerTypes}
                    />
                </Field>
                <Field label="是通过什么渠道了解到EasyDL-桌面版" tip={formTips.sourceType}>
                    <Select
                        name="sourceType"
                        // eslint-disable-next-line
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={formData.sourceType}
                        selectData={sourceTypes}
                    />
                </Field>
                <Field label="昵称" tip={formTips.name}>
                    <Input name="name" placeholder="请输入昵称" onChange={handleChange} onBlur={handleBlur} />
                </Field>
                {
                    isShowCompany &&
                    <Field label="公司名称" tip={formTips.company}>
                        <Input
                            name="company"
                            placeholder="请输入公司名称"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Field>
                }
                <Field label="手机号" tip={formTips.phone}>
                    <Input
                        name="phone"
                        placeholder="请输入手机号"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={formData.phone}
                    />
                </Field>
                <Field label="业务行业" tip={formTips.industry}>
                    {/* <Input
                        name="industry"
                        placeholder="请输入所在行业"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={formData.industry}
                    /> */}
                    <Select
                        name="industry"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={formData.industry}
                        selectData={industry}
                    />
                </Field>
                <Field label="应用场景" tip={formTips.scenes}>
                    <Input
                        name="scenes"
                        placeholder="请输入应用场景"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={formData.scenes}
                    />
                </Field>
                <Field label="是否使用/了解过EasyDL其他产品" tip={formTips.informLevel} radio>
                    <Radio
                        name="informLevel"
                        // eslint-disable-next-line
                        onChange={handleChange}
                        // eslint-disable-next-line
                        onBlur={handleBlur}
                        value={formData.informLevel}
                        radios={informLevels}
                    />
                </Field>
                <Field label="验证码" tip={formTips.verifyCode}>
                    <Input
                        name="verifyCode"
                        placeholder="请输入右侧验证码"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={formData.verifyCode}
                    />
                    <CodeImg />
                </Field>
                <Field required={false}>
                    <div className={cls('submit')} onClick={formSubmit}>
                        提 交
                    </div>
                </Field>
            </div>
        </Modal>
    );
}

export function usePaddleModalForm() {
    const [visible, setVisible] = useState(false);
    const close = useCallback(() => {
        setVisible(false);
    }, []);
    const open = useCallback(() => {
        setVisible(true);
    }, []);
    return [visible, close, open];
}
