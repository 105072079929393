import VideoPlayer, {useVideoPlayer} from 'components/videoPlayer';
import {useCallback, useMemo, useState} from 'preact/hooks';
import {innerHtml} from 'utils';
import classnames from 'classnames';
import ALink from 'components/base/aLink';
import css from './index.less';

// 带有icon 的intro list
export function IconList(props) {
    const {title, data} = props;

    const linkItems = (links) => {
        if (links) {
            return (
                <div className={css.links}>
                    {links.map(({href, name}) => (
                        <ALink href={href} className={css['links_href']} trackTag={`${title}_${name}`}>
                            {name}
                        </ALink>
                    ))}
                </div>
            );
        }
    };

    const iconItems = (data) => {
        const parity = data.length % 3 !== 0;
        return data.map((it) => (
            // eslint-disable-next-line react/jsx-key
            <div className={classnames(css['icon-item'], parity ? css.column2 : css.column3)}>
                <div className={css.icon}>
                    {it.icon ? (
                        <i className={`tzfont tzfont-${it.icon}`} />
                    ) : (
                        <img src={it.img} className={css['img-icon']} />
                    )}
                </div>

                <span className={css.title}>{it.name}</span>
                {innerHtml(<p className={css.desc} innerHtml={it.desc} />)}
                {linkItems(it.links)}
            </div>
        ));
    };
    return <div className={css.easy}>{iconItems(data)}</div>;
}

// 的intro list
export const CardList = (props) => {
    const cardItem = ({data, title}) => {
        const parity = data.length % 3 !== 0;
        const itemClass = classnames(css['card-item'], parity ? css.column2 : css.column3);
        return data.map(({href, desc, name}) => (
            // eslint-disable-next-line react/jsx-key
            <ALink href={href} className={itemClass} trackTag={`${title}_${name}`}>
                <span className={css.title}>{name}</span>
                <p className={css.desc}>{desc}</p>
            </ALink>
        ));
    };

    return <div className={css.easy}>{cardItem(props)}</div>;
};

export const IntroList = (props) => {
    const {data, title} = props;
    const parity = data.length % 3 !== 0;
    const IntroItem = (props) => {
        const {parity, name, desc, link, href = 'javascript:void(0);'} = props;
        const itemClass = classnames(css['intro-item'], parity ? css.column2 : css.column3);

        return (
            <ALink href={href} className={itemClass} trackTag={`${title}_${name}`}>
                <span className={css.title}>{name}</span>
                <p className={css.desc}>{desc}</p>
                <span className={css.link}>{link}</span>
            </ALink>
        );
    };
    const IntroVideoItem = (props) => {
        const {parity, name, desc, link, href = 'javascript:void(0);', video} = props;
        const itemClass = classnames(css['intro-item'], parity ? css.column2 : css.column3);
        const {visible, open, close} = useVideoPlayer();

        return (
            <>
                <VideoPlayer src={video} visible={visible} onClose={close} />

                <ALink className={itemClass} href={href} onClick={open} trackTag={`${title}_${name}`}>
                    <span className={css.title}>{name}</span>
                    <p className={css.desc}>{desc}</p>
                    <span className={css.link}>{link}</span>
                </ALink>
            </>
        );
    };
    return (
        <div className={css.easy}>
            {props.data.map((item) =>
                item.video ? <IntroVideoItem {...item} parity={parity} /> : <IntroItem {...item} parity={parity} />
            )}
        </div>
    );
};

export function CardIntro(props) {
    const {data, id, tag} = props;
    const itemClass = classnames(css['card-intro-item'], css[`card-intro-${id}`]);
    return (
        <div className={css['card-intro']}>
            {data.map((item) => (
                // eslint-disable-next-line react/jsx-key
                <ALink href={item.href} className={itemClass} trackTag={`${tag}_${item.title}`}>
                    <div className={css['card-intro_icon']}>
                        {item.icon ? <i className={`tzfont tzfont-${item.icon}`} /> : <img src={item.img} />}
                    </div>
                    <h3 className={css['card-intro_title']}>{item.title}</h3>
                    <p className={css['card-intro_desc']}>{item.desc}</p>
                    <div className={css['card-intro_link']}>
                        {item.linkName}
                        <span className={css.icon}>
                            <i className="tzfont tzfont-arrow-right-3" />
                        </span>
                    </div>
                </ALink>
            ))}
        </div>
    );
}

// BML icon hover 时动画 card
export function IconHoverCards(props) {
    const {data, tag} = props;

    const cards = useCallback((data) => {
        const parity = data.length % 3 !== 0;
        const itemClass = classnames(css['card-item'], parity ? css.column2 : css.column3);

        return data.map((it) => (
            // eslint-disable-next-line react/jsx-key
            <ALink href={it.linkHref} className={itemClass} trackTag={`${tag}_${it.name}`}>
                <div>
                    {it.icon ? (
                        <i className={`tzfont tzfont-${it.icon} ${css.icon}`} />
                    ) : (
                        <img src={it.img} className={css['img-icon']} />
                    )}
                </div>
                <span className={css.title}>{it.name}</span>
                {innerHtml(<p className={css.desc} innerHtml={it.desc} />)}
                <div className={css.link}>
                    {it.linkText}
                    <i className={'tzfont tzfont-arrow-right-3'} />
                </div>
            </ALink>
        ));
    }, []);
    return <div className={css.iconHoverCards}>{cards(data)}</div>;
}

export function CodeLabIntro(props) {
    const {data} = props;
    return (
        <div className={css.codelab}>
            {data.map(({img, title, intro}) => (
                <div className={css['codelab-item']} key={title}>
                    <div className={css['codelab-item_img']}>
                        <img src={img} />
                    </div>
                    <div className={css['codelab-item-info']}>
                        <h3 className={css['codelab-item_title']}>{title}</h3>
                        <p className={css['codelab-item_intro']}>{intro}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

// paddle feature intro
export function PaddleFeatureIntro({data}) {
    return (
        <div className={css.paddle}>
            {data.map(({img, title, desc}) => (
                <div className={css['paddle-item']} key={title}>
                    <div className={css['paddle-item_icon']}>
                        <img src={img} alt={title} />
                    </div>
                    <div className={css['paddle-item-info']}>
                        <h3 className={css['paddle-item-info_title']}>{title}</h3>
                        <p className={css['paddle-item-info_desc']}>{desc}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}
export function ChipDataCard(props) {
    const {desc, href, name, bg, attrs} = props;
    return (
        <div className={css.chipDataCard} style={{backgroundImage: `url(${bg})`}}>
            <div className={css['chipDataCard-left']}>
                <div>
                    {desc.map((item) => (
                        <p key={item}>{item}</p>
                    ))}
                </div>
                <a href={href} target="_blank" rel="noreferrer" {...attrs}>
                    {name}
                    <i className="tzfont tzfont-arrow-right-3" />
                </a>
            </div>
        </div>
    );
}

export function ClientCard(props) {
    const {data} = props;
    return (
        <div className={css.client}>
            {data.map(({img, title, tag, desc}, index) => (
                <div className={css['client-item']} key={title}>
                    <div className={css['client-item-img']}>
                        <img src={img} alt={title} />
                    </div>
                    <div className={css['client-item-con']}>
                        <h3>{title}</h3>
                        <span>{tag}</span>
                        <p>{desc}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export function CardIntroApply(props) {
    const {data, id, tag} = props;
    const itemClass = classnames(css['card-intro-apply-item'], css[`card-intro-apply-${id}`]);
    return (
        <div className={css['card-intro-apply']}>
            {data.map((item) => (
                // eslint-disable-next-line react/jsx-key
                <ALink href={item.href} className={itemClass} trackTag={`${tag}_${item.title}`}>
                    <div className={css['card-intro_icon']}>
                        {item.icon ? <i className={`tzfont tzfont-${item.icon}`} /> : <img src={item.img} />}
                    </div>
                    <h3 className={css['card-intro_title']}>{item.title}</h3>
                    <p className={css['card-intro_desc']}>{item.desc}</p>
                    <div className={css['card-intro-apply_link']}>
                        <p>适用: {item.application}</p>
                        <p>
                            {item.tag.map((it) => (
                                <span key={it}>{it}</span>
                            ))}
                        </p>
                        <span className={css.icon}>{item.linkName}</span>
                    </div>
                </ALink>
            ))}
        </div>
    );
}

// 文心大模型Card
export function CardModelNlp(props) {
    const {data} = props;
    const {img, title, tag, toLink} = data;
    return (
        <div className={css['card-Model-nlp']}>
            <div className={css['card-Model-nlp-img']}>
                <img src={img} alt='' />
            </div>
            <div className={css['card-Model-nlp-describe']}>
                <div className={css['card-Model-nlp-describe-title']}>
                    {
                        title.map(ite =>
                            (
                                <p key={ite}>
                                    {ite}
                                </p>
                            )
                        )
                    }
                </div>
                <>
                    {
                        toLink.map(({name, href}) => (
                            <a href={href} target="_blank" rel="noreferrer" key={name}>
                                {name}
                            </a>
                    ))}
                </>
                <div className={css['card-Model-nlp-tag']}>
                    {
                        tag.map(item => (
                            <div key={item.name}>
                                <img src={item.icon} />
                                <span>{item.name}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}