import {FC} from 'preact/compat';
import {useState} from 'preact/hooks';
import Modal from '~/components/base/modal';
import styles from './index.less';
import {Checkbox} from '~/components/base/field';

const LOCAL_STORAGE_KEY = 'EASYDL_PADDLE_OFFLINE_MODAL';

const OfflineModal: FC<{
    visible: boolean;
    onClose: () => void;
}> = props => {
    const {visible, onClose} = props;
    const [checked, setChecked] = useState(false);

    return (
        <Modal visible={visible} onClose={onClose} title="功能下线通知">
            <div class={styles['offline-modal-content']}>
                <p>尊敬的百度智能云客户：</p>

                <p>
                    您好，感谢您一直以来对我们产品的信任与支持。由于产品策略调整，EasyDL桌面版于2024年11月1日正式下线，包括官网页面、说明文档等，非常感谢您过去的理解与支持。
                </p>

                <strong>下线时间表：</strong>

                <ul>
                    <li>公告日期：2024年11月1日</li>
                    <li>停止下载和新用户注册：2024年11月1日</li>
                </ul>

                <strong>您的权益：</strong>

                <ol>
                    <li>
                        现有服务保障：已付费用户，您依然可以正常使用EasyDL桌面版。我们将继续为您提供技术支持和维护服务，确保您的使用体验不受影响。
                    </li>
                    <li>
                        替代产品推荐：作为替代方案，我们推荐您使用EasyDL公有云产品，该产品提供了更全面的功能和更高的性能，能够更好地满足您的需求。关于该产品的详细信息和使用指南，请访问我们的官网或联系客服了解更多。
                    </li>
                </ol>

                <strong>联系我们：</strong>

                <p>如果您在产品维保期内遇到问题，请随时联系我们的客户支持团队。您可以通过以下方式与我们取得联系：</p>

                <ul>
                    <li>客服电话：400-920-8999</li>
                    <li>
                        在线工单中心：
                        <a href="https://console.bce.baidu.com/support/" target="_blank">
                            https://console.bce.baidu.com/support
                        </a>
                    </li>
                </ul>

                <strong>致谢：</strong>

                <p>
                    再次感谢您一直以来对我们的支持与理解。我们将继续努力，为您提供更加优质的产品和服务。请继续关注我们的产品更新和公告，了解更多产品信息和功能变化。
                </p>
                <div className={styles['footer']}>
                    <Checkbox
                        checked={checked}
                        onChange={() => {
                            setChecked(!checked);
                        }}
                        label="不再提醒"
                    ></Checkbox>
                    <button
                        onClick={() => {
                            onClose();
                            if (checked) {
                                localStorage.setItem(LOCAL_STORAGE_KEY, '1');
                            }
                        }}
                    >
                        我知道了
                    </button>
                </div>
            </div>
        </Modal>
    );
};

const dontShowAgain = typeof window === 'undefined' ? true : !!localStorage.getItem(LOCAL_STORAGE_KEY);
export const useOfflineModal = () => {
    const [visible, setVisible] = useState(true);

    const modal = dontShowAgain ? null : <OfflineModal visible={visible} onClose={() => setVisible(false)} />;

    return modal;
};
