/*
 * @Author: v_houlin02@baidu.com
 * @Date: 2020-11-26 17:18:09
 * @LastEditTime: 2020-11-28 12:34:08
 * @FilePath: swiperPaginationHoverChange.js
 */

const {useEffect} = require('preact/hooks');

export default function useSwiperPaginationHoverChange(selector) {
    useEffect(() => {
        const oParent = typeof selector === 'object' ? selector : document.querySelector(selector);

        const children = Array.from(oParent.children);
        const handle = (event) => {
            event.target.click();
        };

        children.forEach((item) => {
            item.addEventListener('mouseenter', handle);
        });
        return () => {
            children.forEach((item) => {
                item.removeEventListener('mouseenter', handle);
            });
        };
    }, []);
}
