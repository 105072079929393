import {useState, useEffect, useContext} from 'preact/hooks';
import {TrackType} from 'components/sections';
import {getBodyScrollTop, throttle} from 'utils';
import GravityImg from 'assets/gravity/gravity-affix.gif';
import presale from 'assets/presale.png';

import style from './index.less';

const showTopHeight = 510;

const hasGravityAffix = (type) => ['easydl', 'bml'].includes(type);
const GravityAffix = ({page}) => {
    const workOrder = 'https://ai.baidu.com/easydl/universal-gravitation';
    return hasGravityAffix(page) ? (
        <a
            href={workOrder}
            className={style.gravity}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img src={GravityImg} />
        </a>
    ) : null;
};

export default function Affix(props) {
    const {target = 'easydl'} = props;
    const page = useContext(TrackType).page || 'easydl';
    const workOrder =
        page === 'bml'
            ? 'https://ticket.bce.baidu.com/#/ticket/create~productId=201'
            : 'https://ticket.bce.baidu.com/?_=1611915093463#/ticket/create~productId=171';
    const [showTop, setShowTop] = useState(false);
    useEffect(() => {
        const callback = throttle(() => {
            const winScrollTop = getBodyScrollTop();
            setShowTop(winScrollTop > showTopHeight);
        }, 300);
        callback();
        document.addEventListener('scroll', callback);
        return () => {
            document.removeEventListener('scroll', callback);
        };
    }, []);

    const backTop = () => {
        window.scrollTo({top: 0});
    };
    return (
        <div className={style.aside_container}>
            {/* <GravityAffix page={page} /> */}
            <div className={style.aside}>
                <div className={`${style['aside-item']}`}>
                    <div className={style.aside_item}>
                        <img src={presale} alt="" />
                        <span>合作咨询</span>
                    </div>
                    <span className={style['aside-text']}>合作咨询</span>
                    <div className={style['aside-cooperation-innner']}>
                        <div className={style['aside-cooperation']}>
                            <a
                                href={`https://ai.baidu.com/consultation/cooperation?referrerUrl=/${target}`}
                                className={style['aside-cooperation-wrap']}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div className={style['aside-cooperation-wrap-title']}>
                                    <span className={style['aside-cooperation-presale']} />
                                    售前咨询
                                </div>
                                <div className={style['aside-cooperation-wrap-desc']}>
                                    填写您的业务诉求，专属商务会尽快联系您
                                </div>
                            </a>
                            <a
                                href={workOrder}
                                className={style['aside-cooperation-wrap']}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div className={style['aside-cooperation-wrap-title']}>
                                    <span className={style['aside-cooperation-service']} />
                                    售后工单
                                </div>
                                <div className={style['aside-cooperation-wrap-desc']}>
                                    创建工单快捷反馈问题，会有专业人员为您提供帮助
                                </div>
                            </a>
                            <div className={style['aside-cooperation-wrap']}>
                                <div className={style['aside-cooperation-wrap-title']}>
                                    <span className={style['aside-cooperation-phone']} />
                                    联系销售
                                </div>
                                <div className={style['aside-cooperation-wrap-desc']}>
                                    更多资讯，请拨打
                                    <span className={style['aside-cooperation-wrap-phone']}>400-920-8999 转 1</span>
                                </div>
                            </div>
                            <div className={style['aside-cooperation-triangle']} />
                        </div>
                    </div>
                </div>
                <div className={`${style['aside-item']}`}>
                    <div className={style.aside_item}>
                        <svg
                            className={`${style['aside-icon']} ${style['aside-icon-qm']}`}
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M2 9h7V2H2v7zM0 0h11v11H0V0zm15 9h7V2h-7v7zm-2-9h11v11H13V0zM2 22h7v-7H2v7zm-2-9h11v11H0V13zm22 4v-4h2v6h-7v-2h5zm-9-4h2v11h-2V13zm4 7h2v4h-2v-4zm5 0h2v4h-2v-4z" />
                        </svg>
                        <span>体验AI</span>
                    </div>
                    <span className={style['aside-text']}>AI公众号</span>
                    <div className={style['aside-inner']}>
                        <div className={style['aside-inner-title']}>关注AI公众号</div>
                        <div className={style['aside-inner-sub']}>获取AI资讯，体验AI能力</div>
                        <div className={style['aside-inner-img']} />
                        <div className={style['aside-inner-triangle']} />
                    </div>
                </div>
                <div className={`${style['aside-item']} ${showTop ? '' : style.hidden}`} onClick={backTop}>
                    <svg
                        className={`${style['aside-icon']} ${style['aside-icon-back']}`}
                        viewBox="0 0 18 12"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.121 2.636L17.485 9l-2.121 2.121L9 4.757l-6.364 6.364L.515 9 9 .515l2.121 2.121z"
                            fill-rule="evenodd"
                        />
                    </svg>
                    <span className={style['aside-text']}>返回顶部</span>
                </div>
            </div>
        </div>
    );
}
