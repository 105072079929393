/*
 * @Author: v_houlin02@baidu.com
 * @Date: 2020-11-13 11:12:42
 * @LastEditTime: 2021-05-18 20:37:43
 * @file: cooperation.js
 */
export const formData = {
    title: '填写信息，咨询与合作',
    desc1: '业务与需求信息',
    desc2: '客户基本信息',
    descNote: '（注：我们会尽快与您取得联系，为您提供及时服务）'
};

export const techType = [
    {value: 1, label: '视频技术'},
    {value: 2, label: '图像技术'},
    {value: 3, label: '经典机器学习'},
    {value: 4, label: '飞桨EasyDL-桌面版'},
    {value: 5, label: 'PaddleFlow'},
    {value: 6, label: '3D视觉技术'},
    {value: 7, label: '文本创作'},
    {value: 8, label: 'EasyDL OCR'}
];

export const techService = [
    {value: 101, label: '目标跟踪', key: 1},
    {value: 201, label: '文字识别', key: 2},
    {value: 301, label: '时序预测', key: 3}
];

export const purpose = [
    {value: 'enterprise', label: '企业自用'},
    {value: 'integrator', label: '作为集成商生产并售卖模型'}
];

export const classification = [
    {value: 'image', label: '图像类', desc: '例如：图像分类、物体检测、图像分割'},
    {value: 'nlp', label: '文本类', desc: '例如：文本分类、情感倾向分析、文本实体抽取等'},
    {value: 'audio', label: '语音类', desc: '例如：语音识别、声音分类'},
    {value: 'video', label: '视频类', desc: '例如：视频分类、目标追踪'},
    {value: 'structuredData', label: '结构化数据类', desc: '例如：表格数据预测、时序预测'}
];

export const environment = [
    {value: 'privatization', label: '本地服务器部署'},
    {value: 'sdk', label: '设备端部署'}
];

export const errorMsgArr = [
    {
        field: 'applyReason',
        message: '申请原因不合法'
    },
    {
        field: 'techType',
        message: '技术类型不合法'
    },
    {
        field: 'techService',
        message: '技术服务不合法'
    },
    {
        field: 'industry',
        message: '请选择行业'
    },
    {
        field: 'website',
        message: '网址不能超过120个字符'
    },
    {
        field: 'introduction',
        message: '业务介绍不能超过500个字符'
    },
    {
        field: 'description',
        message: '需求描述不能超过500个字符'
    },
    {
        field: 'scene',
        message: '应用场景不能超过500个字符'
    },
    {
        field: 'purpose',
        message: '主要用途不合法'
    },
    {
        field: 'classification',
        message: '模型类型不合法'
    },
    {
        field: 'environment',
        message: '部署环境不合法'
    },
    {
        field: 'customerType',
        message: '客户类型不合法'
    },
    {
        field: 'company',
        message: '公司名称不能为空'
    },
    {
        field: 'company',
        message: '公司名称不能超过50个字符'
    },
    {
        field: 'customerName',
        message: '业务联系人不能为空'
    },
    {
        field: 'customerName',
        message: '业务联系人姓名不能超过20个字符'
    },
    {
        field: 'phone',
        message: '联系电话格式不合法'
    },
    {
        field: 'email',
        message: '邮箱格式不合法'
    },
    {
        field: 'verifyCode',
        message: '验证码错误'
    }
];

export const companyScale = [
    {
        value: 1,
        label: '1 ~ 300人'
    },
    {
        value: 2,
        label: '300 ~ 1000人'
    },
    {
        value: 3,
        label: '1000 ~ 5000人'
    },
    {
        value: 4,
        label: '5000人以上'
    }
];

export const applyReason = [
    {value: 1, label: '学习用途', key: 1},
    {value: 2, label: '项目用途', key: 2}
];
