import PropTypes from 'prop-types';
import classnames from 'classnames';
import css from './index.less';

export default function Modal(props) {
    const {visible, children, onClose, title, className} = props;
    return (
        <>
            {visible ? (
                <div className={css.modal_wrap}>
                    <div className={classnames(css.modal, className)}>
                        {title && <div className={css.modal_title}>{title}</div>}
                        <div
                            className={css['modal-close']}
                            onClick={onClose}
                            data-track-action='click'
                            data-track-category='飞桨EasyDL桌面版官网-用户信息收集界面'
                            data-track-name='关闭用户信息收集界面'
                        >
                            <i className="tzfont tzfont-close" />
                        </div>
                        <div className={css['modal-container']}>{children}</div>
                    </div>
                </div>
            ) : null}
        </>
    );
}

Modal.prototype = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    title: PropTypes.string
};
Modal.defaultProps = {
    visible: false,
    onClose: () => {},
    title: ''
};
