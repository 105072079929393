import css from './index.less';
import classnames from 'classnames';
import ALink from 'components/base/aLink';

export function TemplateCards(props) {
    const {data} = props;
    const odd = data.length > 2;

    return (
        <div class={css.template_contnet}>
            {data.map((it) => (
                <ALink
                    href={it.link.href}
                    className={classnames(css.template_item, odd ? css.odd : css.even)}
                    trackTag={`模型类型_${it.title}`}
                >
                    <div class={css['img-wrapper']}>
                        <img src={it.img} />
                    </div>
                    <div class={css.detail}>
                        <h3 class={css.title}>{it.title}</h3>
                        <span class={css.desc}>{it.desc}</span>
                        <div class={css.keywords}>
                            {it.keywords.map((it) => (
                                <div class={css.keyword}>{it}</div>
                            ))}
                        </div>
                        <div class={css.link}>{it.link.name}</div>
                    </div>
                </ALink>
            ))}
        </div>
    );
}
