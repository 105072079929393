import {innerHtml} from 'utils';
import style from './index.less';
import ALink from 'components/base/aLink';

export function HighLightNumber(props) {
    const {data} = props;
    return (
        <div class={style.highLightNum}>
            {data.map((prop) => {
                return <HighLightNumberCard {...prop} />;
            })}
        </div>
    );
}

export function HighLightNumberCard(props) {
    const {title, subtitle, desc, button, href, more} = props;

    const content = () => (
        <>
            <div class={`${style['highLightNum-title']} highLightNum-title`}>
                <h3>{title}</h3>
                {button && <button>{button}</button>}
            </div>
            <div>
                {subtitle && (
                    <div class={style['highLightNum-subtitle']}>
                        {innerHtml(<span innerHtml={subtitle} />)}
                        {href && <i class="tzfont tzfont-arrow-right-2" />}
                    </div>
                )}
                {desc.map((item) => {
                    return <div class={style['highLightNum-desc_item']}>{item}</div>;
                })}
            </div>
            {more && more.label && more.href && <ALink href={more.href} className={style['highLightNum-more']}>
                {more.label}
            </ALink>}
        </>
    );
    if (href) {
        return (
            <ALink href={href} trackTag={`产品优势_${title}`} className={style['highLightNum-hover']}>
                {content()}
            </ALink>
        );
    }
    return <div class={style['highLightNum-item']}>{content()}</div>;
}
