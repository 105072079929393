/**
 * @file: share 共用方法
 * @Author: v_panfeng01@baidu.com
 * @Date: 2020-11-09 15:30:12
 * @Last Modified by: v_panfeng01
 * @Last Modified time: 2021-01-27 11:18:40
 */
import {cloneElement} from 'preact';

export function debounce(fn, delay) {
    let last;
    return (...args) => {
        clearTimeout(last);
        last = setTimeout(() => {
            fn.apply(this, args);
        }, delay);
    };
}

export function throttle(fn, delay) {
    let prev = 0;
    let timer = null;
    return (...args) => {
        const now = Date.now();
        clearTimeout(timer);
        if (now - prev > delay) {
            fn.apply(this, args);
            prev = now;
        } else {
            timer = setTimeout(fn, delay - (now - prev)); // 把剩下的时间差走完，再执行一次fn
        }
    };
}

export const $useTrack = (category) => {
    // 可在这添加埋点行为
    const ACTIONS = {
        CLICK: '点击',
        VISIT: '访问'
    };
    const track = (action, label, value) => {
        if (!window._hmt) {
            return;
        }
        window._hmt.push(['_trackEvent', category, action, label, value || 0]);
    };
    return {track, ACTIONS};
};

export function innerHtml(element) {
    if (!element) {
        return null;
    }
    const innerHtml = element.props.innerHtml;
    delete element.props.innerHtml;
    return cloneElement(element, {dangerouslySetInnerHTML: {__html: innerHtml}});
}

export function getQueryParam() {
    const url = decodeURI(location.search);
    if (!url || !~url.indexOf('?')) {
        return {};
    }
    return url
        .substr(1)
        .split('&')
        .reduce(
            (pre, it) => ({
                [it.split('=')[0]]: it.split('=')[1],
                ...pre
            }),
            {}
        );
}

/**
 * 临时方法，保证上线，为链接增加 public path
 */
export function cdn(url) {
    if (!process.env.PUBLIC_PATH) {
        return url;
    }

    const publicPath = process.env.PUBLIC_PATH;
    return [publicPath.replace(/\/$/, ''), url.replace(/^\//, '')].join('/');
}

export function Trim(str) {
    if (typeof str === 'string') {
        return str.replace(/-|\s+/g, '');
    }
    return null;
}

export const LOCALKEYS = {
    PaddleInfo: 'paddle-info'
};

export const getLocalStorageItem = (key) => {
    if (typeof window !== 'undefined') {
        const val = window.localStorage.getItem(key);
        try {
            return JSON.parse(val);
        } catch (error) {
            return val;
        }
    }
};

export const setLocalStorageItem = (key, value) => {
    if (typeof window === 'undefined') {
        return false;
    }
    window.localStorage.setItem(key, JSON.stringify(value));
    return true;
};
