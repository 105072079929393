import {Component, render, createRef} from 'preact';
import {genClassName} from 'utils';
import pcStyle from './toast.pc.less';
import mbStyle from './toast.mb.less';

const isPc = process.env.PLATFORM === 'pc';
const classname = isPc ? genClassName(pcStyle) : genClassName(mbStyle);

const successIcon = require('assets/gravity/icon_success.svg').default;
const failIcon = require('assets/gravity/icon_fail.svg').default;

const SUCCESS = 'success';
const ERROR = 'fail';

let noticeKey = 0;
let notification;
const transitionDuration = 300;

const Notice = (props) => {
    const {type, children} = props;
    return (
        <div className={classname('toast')}>
            {type === SUCCESS ? (
                <img className={classname('icon')} src={successIcon} alt="" />
            ) : (
                <img className={classname('icon')} src={failIcon} alt="" />
            )}
            <span className={classname('children')}>{children}</span>
        </div>
    );
};

class Notification extends Component {
    constructor() {
        super();
        this.state = {notices: []};
        this.removeNotice = this.removeNotice.bind(this);
    }

    getNoticeKey() {
        return ++noticeKey;
    }

    addNotice(notice) {
        const {notices} = this.state;
        notice.key = this.getNoticeKey();
        notice.visible = false;
        notices.push(notice);
        this.setState({notices});
        this.forceUpdate(() => {
            setTimeout(() => {
                notice.visible = true;
                this.setState({notices: [...notices]});
            }, 2);
        });

        if (notice.duration > 0) {
            setTimeout(() => {
                this.removeNotice(notice.key);
            }, notice.duration + transitionDuration);
        }
        return () => {
            this.removeNotice(notice.key);
        };
    }

    removeNotice(key) {
        const {notices} = this.state;
        const notice = notices.find((item) => item.key === key);
        if (!notice) {
            return;
        }
        notice.visible = false;
        this.setState({notices}, () => {
            setTimeout(() => {
                this.setState({
                    notices: notices.filter((notice) => notice.key !== key)
                });
                if (typeof notice.onClose === 'function') {
                    notice.onClose();
                }
            }, transitionDuration);
        });
    }

    render() {
        const {notices} = this.state;
        return (
            <div className={classname('toast-notification')}>
                {notices.map((notice) => (
                    <div
                        key={notice.key}
                        className={classname('transition', notice.visible ? 'visible' : 'hidden')}
                        style={{transitionDuration: `${transitionDuration}ms`}}
                    >
                        <Notice {...notice} />
                    </div>
                ))}
            </div>
        );
    }
}
function createNotification() {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const ref = createRef();
    const root = render(<Notification ref={ref} />, div);
    return {
        addNotice(notice) {
            return ref.current.addNotice(notice);
        },
        destroy() {
            render(null, div, root);
        }
    };
}
const notice = (type, children, duration = 3000, onClose) => {
    if (!notification) {
        notification = createNotification();
    }
    const remove = notification.addNotice({type, children, duration, onClose, visible: false});
    return remove;
};

export default {
    success(children, duration, onClose) {
        return notice(SUCCESS, children, duration, onClose);
    },
    error(children, duration, onClose) {
        return notice(ERROR, children, duration, onClose);
    }
};
