import Modal from '../../base/modal';
import css from './mobileAlert.less';
import {genClassName} from 'utils';
import {useState} from 'preact/hooks';

const cls = genClassName(css);

export function MobileAlert(props) {
    return (
        <Modal visible={props.visible} onClose={props.onClose}>
            <div className={cls('content')}>
                <p>请使用 PC 端下载</p>
                <button onClick={props.onClose}> 确认</button>
            </div>
        </Modal>
    );
}

export function useMobileAlert() {
    const [visible, setVisible] = useState(false);

    return {
        visible,
        show: () => setVisible(true),
        hide: () => setVisible(false)
    };
}
