export const queryString = k => {
    if (typeof window === 'undefined') {
        return {};
    }
    const querySlices = window.location.search
        .substr(1)
        .split('&')
        .filter(Boolean);
    const qs = {};
    (querySlices || []).forEach(slice => {
        const p = slice.split('=');
        qs[p[0]] = p.length ? decodeURIComponent((p[1] || '').replace(/\+/g, ' ')) : '';
    });

    return k ? qs[k] : qs;
};

export const asset = (path, params = {}, options = {}) => {
    if (typeof window === 'undefined') {
        return '';
    }
    const port = window.location.port ? `:${window.location.port}` : '';
    const protocol = options.protocol ? `${options.protocol}:` : window.location.protocol;
    const origin = `${protocol}//${window.location.hostname}${port}`;
    const paramsStr = Object.keys(params)
        .reduce((memo, k) => (params[k] ? memo.concat(`${k}=${encodeURIComponent(params[k])}`) : memo), [])
        .join('&');

    const sep = path[0] === '/' ? '' : '/';
    const querySep = paramsStr ? '?' : '';
    return `${origin}${sep}${path}${querySep}${paramsStr}`;
};
export const assetURL = (path, params = {}, options = {}) => {
    if (typeof window === 'undefined') {
        return '';
    };
    const paramsStr = Object.entries(params).
    filter(([_, value]) => !!value).map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&');
    return `${path}?${paramsStr}`;
};
export const jumpToURL = (url, newBlank) => {
    if (!url && typeof window === 'undefined') {
        return;
    }
    if (newBlank) {
        window.open(url, '_blank');
        return;
    }
    window.location.href = url;
};
export const btoa = (value) => {
    if (typeof window === 'undefined') {
        return '';
    };
    return window.btoa(window.encodeURIComponent(JSON.stringify(value)));
};
export const atob = (value) => {
    if (typeof window === 'undefined') {
        return '';
    };
    return window.decodeURIComponent(window.atob(value));
};

export const loginURL = `https://login.bce.baidu.com/?account=&redirect=${encodeURIComponent(
    'http://ai.baidu.com/easydl/event/redirect'
)}`;
export const baiduCloudQualify = 'https://console.bce.baidu.com/qualify/#/qualify/company';

export const redirectURL = url => `https://login.bce.baidu.com/?account=&redirect=${encodeURIComponent(url)}`;