import style from './index.less';
import ALink from 'components/base/aLink';

const defaultData = [
    {
        logo: require('assets/easydl/logo/01.jpg').default,
        name: '南方电网',
        desc: '检测输电线路安全隐患',
        href: `${process.env.CUSTOMER}/nfdw`
    },
    {
        logo: require('assets/easydl/logo/02.jpg').default,
        name: '太平洋汽车网',
        desc: '定制车型识别',
        href: `${process.env.CUSTOMER}/taipingyang`
    },
    {
        logo: require('assets/easydl/logo/03.jpg').default,
        name: 'Keep',
        desc: '定制奖牌识别模型',
        href: `${process.env.CUSTOMER}/keep`
    },
    {
        logo: require('assets/easydl/logo/04.jpg').default,
        name: '通鼎互联',
        desc: '实现全时安全监控',
        href: `${process.env.CUSTOMER}/tongdinghulian`
    },
    {
        logo: require('assets/easydl/logo/05.jpg').default,
        name: '柳州源创',
        desc: '实现工业质检',
        href: `${process.env.CUSTOMER}/liuzhouyuanchuang`
    },
    {
        logo: require('assets/easydl/logo/06.jpg').default,
        name: '家图网',
        desc: '实现房源图片自动分类',
        href: `${process.env.CUSTOMER}/idcool`
    },
    {
        logo: require('assets/easydl/logo/07.jpg').default,
        name: 'e店佳',
        desc: '实现货架陈列合规性审核',
        href: `${process.env.CUSTOMER}/hzhuihe`
    },
    {
        logo: require('assets/easydl/logo/08.jpg').default,
        name: 'Checkpoint',
        desc: '实现购物车状态监控',
        href: `${process.env.CUSTOMER}/checkpoint`
    },
    {
        logo: require('assets/easydl/logo/09.jpg').default,
        name: '广州凯惠',
        desc: '实现X线影像辅助诊断',
        href: `${process.env.CUSTOMER}/guangzhoukaihui`
    },
    {
        logo: require('assets/easydl/logo/10.jpg').default,
        name: '彦思科技',
        desc: '实现自动识别船舶运输状态',
        href: `${process.env.CUSTOMER}/yihangotms`
    },
    {
        logo: require('assets/easydl/logo/11.jpg').default,
        name: '杭州气象局',
        desc: '实现气象自动观测',
        href: `${process.env.CUSTOMER}/hzqxj`
    },
    {
        logo: require('assets/easydl/logo/12.jpg').default,
        name: '中国专利信息中心',
        desc: '实现商标分类',
        href: `${process.env.CUSTOMER}/zhuanlizhongxin`
    },
    {
        logo: require('assets/easydl/logo/13.jpg').default,
        name: '北京新桥',
        desc: '判断桥梁类型及桥梁瑕疵',
        href: `${process.env.CUSTOMER}/xinqiao`
    },
    {
        logo: require('assets/easydl/logo/14.jpg').default,
        name: '爱包花饰',
        desc: '检测箱包内遗落物品',
        href: `${process.env.CUSTOMER}/aibao`
    },
    {
        logo: require('assets/easydl/logo/15.jpg').default,
        name: '生物记',
        desc: '实现野生生物自动识别',
        href: `${process.env.CUSTOMER}/shengwuji`
    }
];

export function CooperativePartner() {
    return (
        <div className={style['partner']}>
            {defaultData.map(({logo, name, desc, href}) => {
                return (
                    <div className={style['partner-item']} key={name}>
                        <img src={logo} alt={name} className={style['partner-logo']} />
                        <ALink href={href} trackTag={`客户案例_${name}`} className={style['partner-hidden']}>
                            <div className={style['partner-name']}>
                                <span>{name}</span>
                                <i className="tzfont tzfont-arrow-right-2" />
                            </div>
                            <div className={style['partner-desc']}>{desc}</div>
                        </ALink>
                    </div>
                );
            })}
        </div>
    );
}
