export const easydl = {
    pageName: 'easydl',
    logo: {
        href: '/easydl/',
        src: require('assets/icons/pp-easydl.png').default,
        alt: 'EasyDL'
    },
    left: [
        {
            name: '产品介绍',
            type: 'intro',
            items: [
                {
                    title: '通用产品',
                    data: [
                        {
                            name: 'EasyDL 图像',
                            href: process.env.SUBVIS,
                            suffix: {
                                name: '文心大模型',
                                class: 'wenxin-tag'
                            }
                        },
                        {
                            name: 'EasyDL 文本',
                            href: process.env.SUBNLP,
                            suffix: {
                                name: '文心大模型',
                                class: 'wenxin-tag'
                            }
                        },
                        {
                            name: 'EasyDL 语音',
                            href: process.env.SUBAUDIO
                        },
                        {
                            name: 'EasyDL OCR',
                            href: process.env.SUBOCR
                        },
                        {
                            name: 'EasyDL 视频',
                            href: process.env.SUBVIDEO
                        },
                        {
                            name: 'EasyDL 结构化数据',
                            href: process.env.SUBSTRUCTDATA
                        },
                        {
                            name: 'EasyDL 跨模态',
                            href: process.env.SUBVQA,
                            suffix: {
                                name: '文心大模型',
                                class: 'wenxin-tag'
                            }
                        }
                    ]
                },
                {
                    title: '行业产品',
                    data: [
                        {
                            name: 'EasyDL 零售行业版',
                            href: process.env.RETAIL
                        }
                    ]
                },
                {
                    title: '定制服务',
                    data: [
                        {
                            name: '专家团队定制服务',
                            href: process.env.CUSTOMERSERVICE
                        }
                    ]
                },
                {
                    title: '相关产品',
                    data: [
                        {
                            name: '千帆大模型平台',
                            href: process.env.QIANFAN,
                            suffix: {
                                name: '热门',
                                class: 'hot-tag'
                            }
                        },
                        {
                            name: 'BML全功能AI开发（原专业版）',
                            href: process.env.BML
                        }
                    ]
                }
            ]
        },
        {
            name: '操作平台',
            type: 'platform',
            items: [
                {
                    title: 'EasyDL 图像',
                    data: [
                        {
                            name: '图像分类',
                            href: process.env.IMAGE_CONTROL
                        },
                        {
                            name: '物体检测',
                            href: process.env.DETECTION
                        },
                        {
                            name: '图像分割',
                            href: process.env.SEGMENT
                        }
                    ],
                    suffix: {
                        name: '文心大模型',
                        class: 'wenxin-tag'
                    }
                },
                {
                    title: 'EasyDL OCR',
                    data: [
                        {
                            name: '文字识别',
                            href: process.env.OCR
                        }
                    ]
                },
                {
                    title: 'EasyDL 文本',
                    data: [
                        {
                            name: '文本分类 - 单标签',
                            href: process.env.TEXT
                        },
                        {
                            name: '文本分类 - 多标签',
                            href: process.env.TEXTMULTI
                        },
                        {
                            name: '文本实体抽取',
                            href: process.env.TEXTENTITYEXTRACT
                        },
                        {
                            name: '文本实体关系抽取',
                            href: process.env.RELATIONTEXTENTITYEXTRACT,
                            suffix: {
                                class: 'new'
                            }
                        },
                        {
                            name: '情感倾向分析',
                            href: process.env.SENTIMENT
                        },
                        // {
                        //     name: '文本创作',
                        //     href: process.env.ERNIE
                        // },
                        {
                            name: '短文本相似度',
                            href: process.env.TEXTSIM
                        },
                        {
                            name: '评论观点抽取',
                            href: process.env.COMMENTEXTRACT,
                            suffix: {
                                class: 'new'
                            }
                        }
                    ],
                    suffix: {
                        name: '文心大模型',
                        class: 'wenxin-tag'
                    }
                },
                {
                    title: 'EasyDL 视频',
                    data: [
                        {
                            name: '视频分类',
                            href: process.env.VIDEOCLS
                        },
                        {
                            name: '目标跟踪',
                            href: process.env.TRACK
                        }
                    ]
                },
                {
                    title: 'EasyDL 语音',
                    data: [
                        {
                            name: '语音识别',
                            href: process.env.SOUNDIDENTIFY
                        },
                        {
                            name: '声音分类',
                            href: process.env.SOUNDCLS
                        }
                    ]
                },
                {
                    title: 'EasyDL 结构化数据',
                    data: [
                        {
                            name: '表格数据预测',
                            href: process.env.ML
                        },
                        {
                            name: '时序预测',
                            href: process.env.TSF
                        }
                    ]
                },
                {},
                {
                    title: 'EasyDL 跨模态',
                    data: [
                        {
                            name: '图文匹配',
                            href: process.env.VQA,
                            suffix: {
                                class: 'new'
                            }
                        }
                    ],
                    suffix: {
                        name: '文心大模型',
                        class: 'wenxin-tag'
                    }
                },
                {},
                {
                    title: 'EasyDL 零售行业版',
                    data: [
                        {
                            name: '商品检测',
                            href: process.env.COMMODITYIDENTIFICATION
                        }
                    ]
                }
            ]
        },
        {
            name: '应用案例',
            type: 'default',
            href: 'https://ai.baidu.com/easydl/case'
        },
        {
            name: '场景范例',
            type: 'default',
            href: 'https://console.bce.baidu.com/easydl/scene'
        },
        {
            name: '使用文档',
            type: 'doc',
            items: [
                {
                    name: '平台简介',
                    href: 'https://ai.baidu.com/ai-doc/EASYDL/pk38n3odv'
                },
                {
                    name: 'EasyDL 图像',
                    href: 'https://ai.baidu.com/ai-doc/EASYDL/ok38n2w9h',
                    suffix: {
                        name: '文心大模型',
                        class: 'wenxin-tag'
                    }
                },
                {
                    name: 'EasyDL 文本',
                    href: 'https://ai.baidu.com/ai-doc/EASYDL/Akhyjuv9x',
                    suffix: {
                        name: '文心大模型',
                        class: 'wenxin-tag'
                    }
                },
                {
                    name: 'EasyDL 语音',
                    href: 'https://ai.baidu.com/ai-doc/EASYDL/ekhyics9y'
                },
                {
                    name: 'EasyDL OCR',
                    href: 'https://ai.baidu.com/ai-doc/EASYDL/nkgsusf2v'
                },
                {
                    name: 'EasyDL 视频',
                    href: 'https://ai.baidu.com/ai-doc/EASYDL/4khyi07ld'
                },
                {
                    name: 'EasyDL 结构化数据',
                    href: 'https://ai.baidu.com/ai-doc/EASYDL/pkhypzxsv'
                },
                {
                    name: 'EasyDL 零售行业版',
                    href: 'https://ai.baidu.com/ai-doc/EASYDL/Jk38n3gu5'
                },
                {
                    name: 'EasyDL 跨模态',
                    href: 'https://ai.baidu.com/ai-doc/EASYDL/rl2sxlrq5',
                    suffix: {
                        name: '文心大模型',
                        class: 'wenxin-tag'
                    }
                },
                // {
                //     name: '飞桨EasyDL-桌面版',
                //     href: 'https://ai.baidu.com/ai-doc/EASYDL/fl2swlu5b'
                // },
                {
                    name: '产品价格',
                    href: 'https://ai.baidu.com/ai-doc/EASYDL/Xl2tvkkx8'
                },
                // {
                //     name: '联系我们',
                //     href: 'https://ai.baidu.com/ai-doc/EASYDL/Qk38n3gew'
                // }
            ]
        }
    ],
    right: [
        {
            name: '社区',
            type: 'default',
            href: 'https://ai.baidu.com/forum/topic/list/190'
        },
        {
            name: '飞桨官网',
            type: 'default',
            href: 'http://www.paddlepaddle.org/'
        },
        {
            name: 'AI主站',
            type: 'default',
            href: 'https://ai.baidu.com/'
        }
    ]
};
export const geely = {
    pageName: 'geely',
    home: {
        href: '/',
        content: '返回官网',
        icon: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iNjQiIHZpZXdCb3g9IjAgMCA2NCA2NCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNDUuMjUgMi41ODNhMi42NjcgMi42NjcgMCAwIDEgMS43OTcgNC42MzdMMTkuODc3IDMybDI3LjI1IDI0Ljc4YTIuNjY3IDIuNjY3IDAgMSAxLTMuNTg4IDMuOTQ1bC0yOS40MTYtMjYuNzVhMi42NjcgMi42NjcgMCAwIDEgMC0zLjk0M2wyOS4zMzMtMjYuNzVhMi42NTYgMi42NTYgMCAwIDEgMS43OTQtLjY5OXoiIGZpbGw9IiNmZmYiLz48L3N2Zz4='
    },
    left: [],
    right: [
        {
            name: '使用文档',
            type: 'default',
            href: 'todo'
        }
    ]
};
export const bml = {
    pageName: 'bml',
    logo: {
        href: 'https://ai.baidu.com/bml',
        src: require('assets/icons/pp-bml.png').default,
        alt: 'ai.baidu.com'
    },
    left: [
        {
            name: '产品介绍',
            type: 'default',
            href: 'https://ai.baidu.com/ai-doc/BML/Ekhvg0smz'
        },
        {
            name: '操作平台',
            type: 'default',
            href: 'https://ai.baidu.com/bml/app/overview'
        },
        {
            name: '使用文档',
            type: 'doc',
            items: [
                {
                    name: '产品简介',
                    href: 'https://ai.baidu.com/ai-doc/BML/Ekhvg0smz'
                },
                {
                    name: '快速开始',
                    href: 'https://ai.baidu.com/ai-doc/BML/Zkorya4bd'
                },
                {
                    name: '数据服务',
                    href: 'https://ai.baidu.com/ai-doc/BML/Tkhx6desi'
                },
                {
                    name: '预置模型调参建模',
                    href: 'https://ai.baidu.com/ai-doc/BML/Ckhy8wodx'
                },
                {
                    name: 'Notebook建模',
                    href: 'https://ai.baidu.com/ai-doc/BML/xkhx8953r'
                },
                {
                    name: '可视化建模',
                    href: 'https://ai.baidu.com/ai-doc/BML/Ll5634rfm'
                },
                {
                    name: '自定义作业建模',
                    href: 'https://ai.baidu.com/ai-doc/BML/Cl2svt3x7'
                },
                {
                    name: '模型仓库',
                    href: 'https://ai.baidu.com/ai-doc/BML/Pkhyvieyo'
                },
                {
                    name: '视觉模型部署',
                    href: 'https://ai.baidu.com/ai-doc/BML/1khys2qe6'
                },
                {
                    name: 'NLP模型部署',
                    href: 'https://ai.baidu.com/ai-doc/BML/bkhyu3x6q'
                },
                {
                    name: '表格预测模型部署',
                    href: 'https://ai.baidu.com/ai-doc/BML/fkkmmte7m'
                },
                {
                    name: '通用模型部署',
                    href: 'https://ai.baidu.com/ai-doc/BML/Zkhyqugi0'
                },
                {
                    name: '文字识别模型部署',
                    href: 'https://ai.baidu.com/ai-doc/BML/Ukvs4cm6b'
                }
            ]
        },
        {
            name: '飞桨专区',
            type: 'default',
            href: '/bml/paddlepaddle'
        },
        {
            name: 'PaddleHelix',
            type: 'default',
            href: 'https://paddlehelix.baidu.com/'
        }
    ],
    right: [
        {
            name: '社区',
            type: 'default',
            href: 'https://ai.baidu.com/forum/topic/list/208'
        },
        {
            name: '飞桨官网',
            type: 'default',
            href: 'http://www.paddlepaddle.org/'
        },
        {
            name: 'AI主站',
            type: 'default',
            href: 'https://ai.baidu.com/'
        }
    ],
    target: 'https://ai.baidu.com/bml/app/overview'
};
export const data = {
    pageName: 'data',
    logo: {
        href: 'https://ai.baidu.com',
        src: require('assets/icons/data-logo.png').default,
        alt: 'ai.baidu.com'
    },
    left: [
        {
            name: '产品介绍',
            type: 'default',
            href: 'https://ai.baidu.com/ai-doc/EasyData/ek9k9014h'
        },
        {
            name: '数据服务',
            type: 'default',
            href: 'https://ai.baidu.com/easydata/app/dataset/list'
        },
        {
            name: '使用文档',
            type: 'default',
            items: [
                {
                    name: '平台介绍',
                    href: 'https://ai.baidu.com/ai-doc/EasyData/ek9k9014h'
                },
                {
                    name: '数据采集',
                    href: 'https://ai.baidu.com/ai-doc/EasyData/gk9ie4h5u'
                },
                {
                    name: '数据标注',
                    href: 'https://ai.baidu.com/ai-doc/EasyData/6k9jouf9h'
                },
                {
                    name: '数据清洗',
                    href: 'https://ai.baidu.com/ai-doc/EasyData/pk9joxftm'
                },
                {
                    name: '联系我们',
                    href: 'https://ai.baidu.com/ai-doc/EasyData/Bk9ckrzeg'
                }
            ]
        }
    ],
    right: [
        {
            name: '社区',
            type: 'default',
            href: 'https://ai.baidu.com/forum/topic/list/205'
        },
        {
            name: '飞桨官网',
            type: 'default',
            href: 'http://www.paddlepaddle.org/'
        }
    ],
    target: 'https://ai.baidu.com/easydata/app'
};
export const edge = {
    pageName: 'edge',
    logo: {
        href: 'https://ai.baidu.com',
        src: require('assets/icons/edge-logo.png').default,
        alt: 'ai.baidu.com'
    },
    left: [
        {
            name: '产品介绍',
            type: 'default',
            href: 'https://ai.baidu.com/easyedge'
        },
        {
            name: '使用文档',
            type: 'default',
            href: 'https://ai.baidu.com/ai-doc/EASYEDGE/yk3fj850y'
        },
        {
            name: '常见问题',
            type: 'default',
            href: 'https://ai.baidu.com/ai-doc/EASYEDGE/7k3fj858h'
        }
    ],
    right: [
        {
            name: '社区',
            type: 'default',
            href: 'https://ai.baidu.com/forum/topic/list/199'
        },
        {
            name: '飞桨官网',
            type: 'default',
            href: 'https://www.paddlepaddle.org.cn/'
        },
        {
            name: 'AI主站',
            type: 'default',
            href: 'https://ai.baidu.com/'
        }
    ],
    target: '/easyedge/app/models'
};
export const enterprise = {
    pageName: 'enterprise',
    logo: {
        href: '/easydl/',
        src: require('assets/icons/easydl-logo.png').default,
        alt: '飞桨企业版'
    },
    left: [
        {
            name: '产品介绍',
            type: 'intro',
            items: [
                {
                    title: '通用产品',
                    data: [
                        {
                            name: 'EasyDL 图像',
                            href: process.env.SUBVIS
                        },
                        {
                            name: 'EasyDL 文本',
                            href: process.env.SUBNLP
                        },
                        {
                            name: 'EasyDL 语音',
                            href: process.env.SUBAUDIO
                        },
                        {
                            name: 'EasyDL OCR',
                            href: process.env.SUBOCR
                        },
                        {
                            name: 'EasyDL 视频',
                            href: process.env.SUBVIDEO
                        },
                        {
                            name: 'EasyDL 结构化数据',
                            href: process.env.SUBSTRUCTDATA
                        }
                    ]
                },
                {
                    title: '行业产品',
                    data: [
                        {
                            name: 'EasyDL 零售行业版',
                            href: process.env.RETAIL
                        }
                    ]
                },
                {
                    title: '相关产品',
                    data: [
                        {
                            name: 'BML全功能AI开发（原专业版）',
                            href: process.env.BML
                        }
                    ]
                }
            ]
        },
        {
            name: '操作平台',
            type: 'platform',
            items: [
                {
                    title: 'EasyDL 图像',
                    data: [
                        {
                            name: '图像分类',
                            href: process.env.IMAGE_CONTROL
                        },
                        {
                            name: '物体检测',
                            href: process.env.DETECTION
                        },
                        {
                            name: '图像分割',
                            href: process.env.SEGMENT
                        }
                    ]
                },
                {
                    title: 'EasyDL OCR',
                    data: [
                        {
                            name: '文字识别',
                            href: process.env.OCR
                        }
                    ]
                },
                {
                    title: 'EasyDL 文本',
                    data: [
                        {
                            name: '文本分类 - 单标签',
                            href: process.env.TEXT
                        },
                        {
                            name: '文本分类 - 多标签',
                            href: process.env.TEXTMULTI
                        },
                        {
                            name: '文本实体抽取',
                            href: process.env.TEXTENTITYEXTRACT
                        },
                        {
                            name: '文本实体关系抽取',
                            href: process.env.RELATIONTEXTENTITYEXTRACT
                        },
                        {
                            name: '情感倾向分析',
                            href: process.env.SENTIMENT
                        },
                        {
                            name: '短文本相似度',
                            href: process.env.TEXTSIM
                        }
                    ]
                },
                {
                    title: 'EasyDL 视频',
                    data: [
                        {
                            name: '视频分类',
                            href: process.env.VIDEOCLS
                        },
                        {
                            name: '目标跟踪',
                            href: process.env.TRACK
                        }
                    ]
                },
                {
                    title: 'EasyDL 语音',
                    data: [
                        {
                            name: '语音识别',
                            href: process.env.SOUNDIDENTIFY
                        },
                        {
                            name: '声音分类',
                            href: process.env.SOUNDCLS
                        }
                    ]
                },
                {
                    title: 'EasyDL 结构化数据',
                    data: [
                        {
                            name: '表格数据预测',
                            href: process.env.ML
                        },
                        {
                            name: '时序预测',
                            href: process.env.TSF
                        }
                    ]
                },
                {},
                {
                    title: 'EasyDL 零售行业版',
                    data: [
                        {
                            name: '商品检测',
                            href: process.env.COMMODITYIDENTIFICATION
                        }
                    ]
                }
            ]
        },
        {
            name: '应用案例',
            type: 'default',
            href: 'https://ai.baidu.com/easydl/case'
        }
    ],
    right: [
        {
            name: '资讯',
            type: 'default',
            href: 'https://ai.baidu.com/forum/topic/list/190'
        },
        {
            name: '社区',
            type: 'default',
            href: 'https://ai.baidu.com/forum/topic/list/190'
        },
        {
            name: '控制台',
            type: 'default',
            href: 'https://ai.baidu.com/forum/topic/list/190'
        }
    ]
};
export const wenxin = {
    pageName: 'wenxin',
    logo: {
        href: 'https://ai.baidu.com/wenxinworkshop',
        src: require('assets/wenxin/wenxin-ai-logo.png').default,
        alt: '百度智能云千帆大模型平台'
    },
    left: [
        {
            name: '操作台',
            type: 'default',
            href: 'https://console.bce.baidu.com/qianfan/'
        },
        {
            name: '使用文档',
            type: 'default',
            href: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Slfmc9dds'
        }
    ],
    right: [
        {
            name: 'AI主站',
            type: 'default',
            href: 'https://ai.baidu.com/'
        }
    ]
};