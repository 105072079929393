import PropTypes, { string } from 'prop-types';
import classnames from 'classnames';
import css from './index.less';
export function UseStep(props) {
    const {data, stepName} = props;
    const length = data.length;
    return (
        <div className={classnames(css.use_step, css[stepName])}>
            {data.map(({name, desc}, i) => (
                // eslint-disable-next-line react/jsx-key
                <div className={classnames(css.use_step_item, css[`__column${length}`])}>
                    <div className={css.use_step_index}>
                        <span>{`0${i + 1}`}</span>
                        <div className={css.arrow} />
                    </div>
                    <div className={`${css.title} ${length > 4 ? css.maxLimit : ''}`}>{name}</div>
                    <div className={css.desc}>{desc}</div>
                </div>
            ))}
        </div>
    );
}

UseStep.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            desc: PropTypes.string
        })
    ).isRequired,
    stepName: string
};

UseStep.defaultProps = {
    data: [],
    stepName: ''
};
