/*
 * @Author: v_houlin02@baidu.com
 * @Date: 2020-11-25 11:28:34
 * @LastEditTime: 2021-06-08 15:43:54
 * @file: ocr.js
 */

export default {
    config: {
        trackType: 'EasyDL文字识别官网',
        layout: 'easydl',
        page: 'ocr'
    },
    banner: {
        name: 'ocr',
        title: 'EasyDL OCR',
        subTitle:
            '定制识别图片中的文字信息，结构化输出关键字段内容，极大提升OCR模型训练效率，满<br/>足个性化卡证票据识别需求；支持公有云服务、私有化部署多种使用方式',
        bg: require('assets/banner/lite-ocr.jpg').default,
        buttonGroup: [
            {
                name: '在线使用',
                href: process.env.OCR,
                size: 'small',
                type: 'primary',
                trackTag: '申请测试_官网上方',
                attrs: {
                    'data-track-action': 'click',
                    'data-track-category': 'EasyDL在线使用',
                    'data-track-name': 'EasyDL-产品页面-在线使用',
                    'data-track-value': ''
                }
            },
            {
                name: '使用文档',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/nkgsusf2v',
                size: 'small',
                plain: true,
                trackTag: '使用文档_官网上方'
            }
        ],
        linkGroup: [{
            name: '合作咨询',
            href: 'https://ai.baidu.com/easydl/cooperation?techType=8',
            size: 'small',
            plain: true,
            className: 'gold'
        }]
    },
    feature: {
        title: '全面领先的特色功能效果',
        data: [
            {
                title: '零门槛操作',
                subtitle: '无需算法基础即可完成模型训练',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/nkgsusf2v#零门槛操作',
                desc: ['无需关注算法细节', '预置最佳训练参数', '一站式流程化训练']
            },
            {
                title: '高精度效果',
                subtitle: '平均准确率<b>90%+</b>',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/nkgsusf2v#高精度效果',
                desc: ['预置百度OCR最佳实践预训练模型', '丰富的商用模型实训经验', '融合视觉、语义多模态特征进行训练']
            },
            {
                title: '低成本数据',
                subtitle: '真实数据+虚拟数据混合训练',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/nkgsusf2v#低成本数据',
                desc: [
                    '提供可视化数据管理平台',
                    '智能预标注，仅需核对修改即可完成标注',
                    '批量生成虚拟数据，大幅扩充数据集'
                ]
            },
            {
                title: '超灵活部署',
                subtitle: '支持多种部署方式',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/nkgsusf2v#超灵活部署',
                desc: [
                    '云端一键部署，即刻生成 Restful API',
                    '公有云部署，毫秒级调用响应，高并发承载',
                    '完整平台支持私有化部署，搭建 AI 中台'
                ]
            }
        ]
    },

    step: {
        title: '简单四步体验全流程，轻松获得OCR识别模型',
        desc: '零门槛OCR结构化识别模型训练平台，为客户提供高效便捷的数据管理、定制训练、模型部署等全流程服务',
        data: [
            {name: '创建模型', desc: '根据不同项目需求，创建想要训练的模型'},
            {name: '准备数据', desc: '上传并标注训练所需数据，并可批量生成虚拟数据'},
            {name: '训练模型', desc: '无需调参，选择数据集一键启动模型训练'},
            {name: '部署模型', desc: '快速部署上线，即刻生成公有云 API 以供调用'}
        ]
    },

    scene: {
        title: '广泛成熟的实践应用，助力产业AI升级',
        data: [
            {
                name: '证照电子化审批',
                desc:
                    '对政府、金融、企业等审批流程中涉及到的各种证照，如食品/药品经营许可证、特种设备审批证等，进行定制训练，快速提取关键信息完成线上审批，实现 7*24 小时无间断服务',
                banner: require('assets/subpage/ocr-certificates.png').default
            },
            {
                name: '财税报销电子化',
                desc:
                    '对不同金融或税务机构发型的各类财务发票、银行单据进行定制训练，快速实现财税凭证的录入，大幅度节约凭证邮寄、录入成本，实现线上电子化报税报销',
                banner: require('assets/subpage/ocr-finance.jpg').default
            },
            {
                name: '保险智能理赔',
                desc:
                    '对不同版式的保单或不同地区、不同医疗系统开具的医疗票据进行定制训练，实现保险理赔相关材料的快速录入，降低人力成本，提升保险理赔的业务安全性及快捷性',
                banner: require('assets/subpage/ocr-insurance.jpeg').default,
                imgs: [
                    {
                        href: 'https://cloud.baidu.com/customer/case/taikang.html',
                        src: require('assets/easydl/logo/17.jpg').default
                    }
                ]
            }
        ]
    },

    recommend: {
        title: '相关推荐',
        data: [
            {
                name: 'iOCR自定义模版文字识别',
                href: 'https://ai.baidu.com/tech/iocr',
                desc:
                    '无需数据标注，针对固定版式卡证票据快速框选即可配置模板匹配及结构化识别规则，提取关键字段，实现个性化识别冷启动'
            },
            {
                name: '卡证文字识别',
                href: 'https://ai.baidu.com/tech/ocr_cards',
                desc: '提供对身份证、银行卡、营业执照等常用卡片及证照的文字内容进行结构化识别的服务'
            },
            {
                name: '财务票据文字识别',
                href: 'https://ai.baidu.com/tech/ocr_finance',
                desc: '对财税报销、税务核算等场景所涉及的10+类常见票据进行结构化识别，并支持混贴票据自动切分识别'
            },
            {
                name: '医疗票据文字识别',
                href: 'https://ai.baidu.com/tech/ocr_medical',
                desc: '结构化识别全国范围的医疗票据、医疗费用结算单、病案首页、保险单等单据'
            },
            {
                name: '通用场景文字识别',
                href: 'https://ai.baidu.com/tech/ocr_general',
                desc: '覆盖多种通用场景、多种语言的高精度整图文字检测和识别服务，包括各类印刷和手写文档、网络图片、表格、数字、二维码等'
            },
            {
                name: '交通场景文字识别',
                href: 'https://ai.baidu.com/tech/ocr_cars',
                desc: '结构化识别行驶证、驾驶证、车牌、VIN码、车辆合格证、机动车销售发票等'
            }
        ]
    },

    productForm: {
        title: '使用方式',
        data: [
            {
                name: 'onLine-easydl',
                title: '公有云服务',
                desc: '定制训练的模型可在云端一键部署，即刻生成 Restful API 以供调用，无需依赖本地算力环境；支持高并发承载，低成本、高可用',
                href: [
                    {
                        text: 'API文档',
                        href: 'https://ai.baidu.com/ai-doc/EASYDL/tkneo6joo'
                    },
                ]
            },
            {
                name: 'local-easydl',
                title: '私有化部署',
                desc: '完整训练平台支持部署至本地服务器或专有云，可用于搭建企业内部AI中台；也可将训练产出的模型容器化打包进行本地部署，快速落地应用。提供一体机和软件部署包两种私有化方案，灵活高效，安全可靠',
                href: [{
                    text: '合作咨询',
                    href: 'https://ai.baidu.com/easydl/cooperation?techType=8'
                }]
            }
        ]
    }
};
