export default {
    config: {
        trackType: 'EasyDL文本官网',
        layout: 'easydl',
        page: 'nlp'
    },
    banner: {
        name: 'nlp',
        title: 'EasyDL 自然语言处理方向',
        subTitle: '基于文心·NLP大模型领先的语义理解技术，为企业/开发者提供一整套NLP定制与应用能力',
        bg: require('assets/subpage/nlp-banner.jpg').default,
        buttonGroup: [
            {
                name: '在线使用',
                className: 'js-consoleModalBtn',
                size: 'small',
                type: 'primary',
                trackTag: '在线使用_官网上方',
                attrs: {
                    'data-track-action': 'click',
                    'data-track-category': 'EasyDL在线使用',
                    'data-track-name': 'EasyDL-产品页面-在线使用',
                    'data-track-value': ''
                }
            },
            {
                name: '使用文档',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/Akhyjuv9x',
                size: 'small',
                plain: true,
                trackTag: '使用文档_官网上方'
            },
            {
                name: '产品定价',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/yl2tvkl44',
                size: 'small',
                plain: true,
                trackTag: '产品定价_官网上方'
            }
        ]
    },
    modelTypes: {
        title: '基于文心大模型快速定制高精度NLP模型',
        data: [
            {
                title: '文本分类-单标签',
                desc: '定制分类标签实现文本内容的自动分类，每个文本属于一种标签类型',
                keywords: ['新闻推荐', '电商评论分类'],
                img: require('assets/subpage/nlp-text.png').default,
                link: {
                    name: '在线使用',
                    href: 'https://console.bce.baidu.com/easydl/4/400/models'
                }
            },
            {
                title: '文本分类-多标签',
                desc: '定制分类标签实现文本内容的自动分类，每个文本可以属于多个标签类型',
                keywords: ['兴趣画像匹配'],
                img: require('assets/subpage/nlp-textMulti.png').default,
                link: {
                    name: '在线使用',
                    href: 'https://console.bce.baidu.com/easydl/4/401/models'
                }
            },
            {
                title: '情感倾向分析',
                desc: '对包含主观信息的文本进行情感倾向性判断，情感极性分为积极、消极、中性',
                keywords: ['电商评论分析', '舆情分析'],
                img: require('assets/subpage/nlp-sentiment.png').default,
                link: {
                    name: '在线使用',
                    href: 'https://console.bce.baidu.com/easydl/9/900/models'
                }
            },
            {
                title: '文本实体抽取',
                desc: '定制文本实体抽取模型，实现从文本中抽取内容，并将抽取内容识别为自定义的类别标签',
                keywords: ['金融研报信息识别', '法律案件抽取'],
                img: require('assets/subpage/nlp-textSim.png').default,
                link: {
                    name: '在线使用',
                    href: 'https://console.bce.baidu.com/easydl/10/1000/models'
                }
            },
            {
                title: '短文本相似度',
                desc: '定制短文本相似度模型，可以将两个短文本进行对比计算，获得两个文本的相似度值',
                keywords: ['搜索推荐', '知识问答'],
                img: require('assets/subpage/nlp-short-text.png').default,
                link: {
                    name: '在线使用',
                    href: process.env.TEXTSIM
                }
            },
            {
                title: '文本实体关系抽取',
                desc: '定制文本实体关系抽取模型，可以针对文本中的实体，定义两个实体的实体关系',
                keywords: ['行业知识图谱构建', '行业问答推理'],
                img: require('assets/subpage/nlp-relation-extraction.png').default,
                link: {
                    name: '在线使用',
                    href: process.env.RELATIONTEXTENTITYEXTRACT
                }
            },
            // {
            //     title: '文本创作',
            //     desc: '基于文心ERNIE3.0，知识增强的百亿参数大模型为基座，定制训练文本创作模型',
            //     keywords: ['广告创意', '文种风格创意', '文学创作'],
            //     img: require('assets/subpage/nlp-ernie.png').default,
            //     link: {
            //         name: '在线使用',
            //         href: process.env.ERNIE
            //     }
            // },
            {
                title: '评论观点抽取',
                desc: '定制评论观点抽取模型，实现从文中抽取评价片段、评价维度、评价观点、并判断评价情感倾向。',
                keywords: ['辅助消费决策', '互联网舆情分析'],
                img: require('assets/subpage/comment-extract.png').default,
                link: {
                    name: '在线使用',
                    href: process.env.COMMENTEXTRACT
                }
            }
        ]
    },

    feature: {
        title: '全面领先的特色功能效果',
        data: [
            {
                title: '零门槛操作',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/ok38n2w9h#高精度效果',
                desc: ['一站式自动化训练', '拖拽式极简操作', '端到端模型训练']
            },
            {
                title: '高精度效果',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/Ckwud0hk2',
                desc: [
                    '使用百度文心·NLP大模型作为训练基座',
                    '少量数据即可获得理想的模型效果',
                    '持续语义学习理解框架助力模型效果'
                ]
            },
            {
                title: '低成本数据',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/ok38n2w9h#数据支持',
                desc: ['支持7种任务的数据标注工具', '提供数据的可视化管理', '提供部分任务的智能标注，减少人工成本']
            },
            {
                title: '超灵活部署',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/ok38n2w9h#丰富的部署方案',
                desc: [
                    '支持一键部署百度云服务并支持高并发',
                    '支持本地私有化部署，便捷部署',
                    '合理的计价方案，低成本部署'
                ]
            }
        ]
    },

    step: {
        title: '简单高效三步体验流程，轻松获得文本分类模型',
        //   eslint-disable-next-line
        desc: '一站式模型定制，全流程可视化操作，最快15分钟获得高精度AI模型，',
        data: [
            {name: '准备数据集', desc: '上传并标注训练所需数据'},
            {name: '训练模型', desc: '根据页面提示高效配置网络与部署方式，一键启动模型训练'},
            {name: '模型部署', desc: '完成模型训练，高效生成公有云或私有化部署'}
        ]
    },

    scene: {
        title: '广泛成熟的实践应用，助力产业AI升级',
        data: [
            {
                name: '金融领域',
                desc:
                    '可对金融领域中常见的招股书募股书法律合同企业舆情分析研究报告等，通过文本模型进行抽取，方便进行量化分析等',
                banner: require('assets/subpage/nlp-financial.png').default
            },
            {
                name: '媒体领域',
                desc:
                    '针对媒体领域中的新闻内容，可进行新闻自动分类，新闻标签，新闻关键信息抽取，新闻内容审核，新闻摘要/标题生成，标题相似度分析等，可大幅减少机械性重复工作',
                banner: require('assets/subpage/nlp-media.png').default
            },
            {
                name: '电商领域',
                desc: '通过对商品的用户评论分析，可针对用户意见进行分类，观点抽取与分析情感倾向分析',
                banner: require('assets/subpage/nlp-e-commerce.png').default
            },
            {
                name: '政务领域',
                desc:
                    '通过情感倾向分析来监控舆情信息，有助于对特大事件的舆情倾向进行量化追踪。同时也可以对民众反馈，进行自动分类和相关核心信息的提取，降低政务处理成本',
                banner: require('assets/subpage/nlp-government-affairs.png').default
            }
        ]
    },

    recommend: {
        title: '相关推荐',
        data: [
            {
                name: '文心大模型',
                href: 'https://wenxin.baidu.com',
                desc: '产业级知识增强大模型，百行千业AI开发的首选基座大模型'
            },
            {
                name: '情感倾向分析',
                href: 'https://ai.baidu.com/tech/nlp_apply/sentiment_classify',
                desc: '自动对包含主观信息的文本进行情感倾向性判断，为口碑分析、话题监控、舆情分析等应用提供基础技术支持'
            },
            {
                name: '短文本相似度',
                href: 'https://ai.baidu.com/tech/nlp_basic/simnet',
                desc: '提供不同短文本之间语义相似度的计算，帮助快速实现推荐、检索、排序等应用'
            }
        ]
    },


    featureModule: {
        title: '文心 . NLP大模型的技术特色',
        desc: '',
        data: [
            {
                title: '任务效果领先',
                desc: '刷新60个以上中文NLP任务基准，并登顶SuperGLUE全球榜首',
                link: {
                    name: '了解详情',
                    href: 'https://wenxin.baidu.com/wenxin/nlp'
                },
                banner: require('assets/subpage/nlp-featureModule1.png').default
            },
            {
                title: '少量样本快捷训练',
                desc: '少量训练数据即可达到良好效果，训练便捷，效果提升迅速',
                link: {
                    name: '了解详情',
                    href: 'https://wenxin.baidu.com/wenxin/nlp'
                },
                banner: require('assets/subpage/nlp-featureModule2.png').default
            },
            {
                title: '学习海量的中文数据与知识',
                desc: '基于来源丰富的中文文本及大规模知识图谱训练，总量达4TB',
                link: [
                    {
                        name: '了解详情',
                        href: 'https://wenxin.baidu.com/wenxin/nlp'
                    }
                ],
                banner: require('assets/subpage/nlp-featureModule3.png').default
            },
            {
                title: '多场景创作能力 ',
                desc: '大模型能够进行内容生成（AIGC），助力内容规模化生产',
                link: [
                    {
                        name: '了解详情',
                        href: 'https://wenxin.baidu.com/wenxin/ernie'
                    }
                ],
                banner: require('assets/subpage/nlp-featureModule4.png').default
            }
        ]
    },
};
