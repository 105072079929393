import css from './index.less';
import ALink from 'components/base/aLink';
import classnames from 'classnames';

export function Feature(props) {
    const {data} = props;
    return (
        <div class={css.feature}>
            {data.map((it, index) => (
                <div className={css['feature-item']} key={it.title}>
                    <div class={classnames(css['txt-area'], index % 2 !== 0 && css.right)}>
                        <h3 class={css.title}>{it.title}</h3>
                        <p class={css.desc}>{it.desc}</p>
                        {[it.link].flat().map(item => (
                            <ALink
                                className={css[item.class || 'link']}
                                href={item.href}
                                trackTag={`产品优势_${it.title}`}
                                key={item.href}
                            >
                                {item.name}
                            </ALink>
                        ))}
                    </div>
                    <div class={classnames(css['img-area'])}>
                        <img src={it.banner} />
                    </div>
                </div>
            ))}
        </div>
    );
}
