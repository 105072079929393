import {useState, useMemo, useEffect} from 'preact/hooks';
import css from './index.less';
import classnames from 'classnames';
import ALink from 'components/base/aLink';

export function TabCard(props) {
    const [curTab, setCurTab] = useState(0);
    const {data, title} = props;

    const Case = (data) => {
        if (data && data.length !== 0) {
            return data.map(({href, name, src}) =>
                href ? (
                    <ALink href={href} target="_blank" trackTag={`${title}_合作案例`}>
                        <img class={css['logo_img']} src={src} alt={name} />
                    </ALink>
                ) : (
                    <div>
                        <img class={css['logo_img']} src={src} alt={name} />
                    </div>
                )
            );
        }
        return <div class={css['look_forward']}>敬请期待</div>;
    };

    return (
        <>
            <div class={css['tech_tab']}>
                {data.map((it, index) => (
                    <div
                        class={classnames(css['tech_item_tab'], curTab === index && css.active)}
                        onClick={() => setCurTab(index)}
                    >
                        {it.name}
                    </div>
                ))}
            </div>
            <div class={css['tech_item']}>
                <div class={css['tech_item_left']}>
                    <img src={data[curTab].image} />
                </div>
                <div class={css['tech_item_right']}>
                    <h3 class={css['sub_title']}>{data[curTab].title}</h3>
                    <p class={css['sub_desc']}>{data[curTab].desc}</p>
                    <div class={css['use_case']}>
                        <div class={css['use_case_wrapper']}>{Case(data[curTab].imgs)}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export function TabCardBox(props) {
    const [curTab, setCurTab] = useState(0);
    const {data} = props;

    const Case = (data) => {
            const href = `https://console.bce.baidu.com/easydl/scene/details?category=${data.category}`;
            return <a className={css.look_forward_a} href={href} target="_blank" without rel="noreferrer">查看详情</a>;
    };

    return (
        <>
            <div className={css.tech_tab}>
                {data.map((it, index) => (
                    <div
                        className={classnames(css.tech_item_tab, curTab === index && css.active)}
                        onClick={() => setCurTab(index)}
                        key={it.name}
                    >
                        {it.name}
                    </div>
                ))}
            </div>
            <div className={classnames(css.tech_item, css.tech_item_new)}>
                <div className={css.tech_item_left}>
                    <img src={data[curTab].image} />
                </div>
                <div className={css.tech_item_right}>
                    <h3 className={css.sub_title}>{data[curTab].title}</h3>
                    <p className={css.sub_desc}>
                        {data[curTab].desc}
                        <span className={css.sub_tag}>标签：{data[curTab].tag}</span>
                    </p>
                    <div className={css.use_case}>
                        <div className={css.use_case_wrapper}>{Case(data[curTab])}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export function TabCardFeature(props) {
    const [curTab, setCurTab] = useState(0);
    const [curTabF, setCurTabF] = useState(0);
    const {data, unfold} = props;
    const srclLink = useMemo(() => data[curTab].data[curTabF].image, [curTab, curTabF]);
    useEffect(() => {
        setCurTabF(0);
    }, [curTab]);
    return (
        <>
            <div className={classnames(css.tech_tab, css.tech_tab_f)}>
                {data.map(({title, data}, index) => (
                    <div
                        className={classnames(css.tech_item_tab, curTab === index && css.active)}
                        onClick={() => setCurTab(index)}
                        key={title}
                    >
                        {title}
                    </div>
                ))}
            </div>
            <div className={css.tech_item_f}>
                <div className={css.tech_item_f_left}>
                    <img src={srclLink} />
                </div>
                <div className={css.tech_item_f_right}>
                    {
                        data[curTab].data.map(({title, desc}, index) => (
                            <div
                                className={classnames(css.tech_item_f_right_content, curTabF === index && css.active_f)}
                                onMouseEnter={() => setCurTabF(index)}
                                key={title}
                            >
                                <div>
                                    <span>{title}</span>
                                    <img src={unfold} />
                                </div>
                                {
                                    curTabF === index && <p>{desc}</p>
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
}
