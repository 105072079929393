/**
 *header 目前作为子应用，被其项目所应用，
 *所以这个地方的改动会影响其他地方。
 *对本文件结构性改动需要向粘永报备
 */

import classnames from 'classnames';
import {useMemo} from 'preact/hooks';
import css from './index.less';
import {PreLink} from '~/components/base/aLink';
import {genClassName} from '~/utils';
import {
    BMLActions,
    EasyDLActions,
    SmartasrActions,
    GeelyActions,
    WenxinActions
} from './userPanel';

const cls = genClassName(css);

const platForm2Header = {
    bml: BMLActions,
    easydl: EasyDLActions,
    geely: GeelyActions,
    smartasr: SmartasrActions,
    wenxin: WenxinActions
};

const Link = PreLink({
    target: '_self'
});

function Default(props) {
    const {href, name, items} = props;
    return (
        <div className={cls('nav-item', 'auto-width')}>
            {href ? (
                <Link href={href} trackTag={`顶部导航栏_${name}`}>
                    {name}
                </Link>
            ) : (
                <>
                    <a>{name}</a>
                    <div className={cls('sub-div')}>
                        {items.map(({href, name}) => (
                            <div className={cls('sub-div-item')} key={name}>
                                <div className={cls('sub-div-item_link')}>
                                    <Link href={href} trackTag={`顶部导航栏_${name}`}>
                                        {name}
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

const PlatformItems = (props) => {
    const {items, filterNum} = props;
    return (
        <div>
            {items
                .filter((_, index) => index % 2 === filterNum)
                .map(
                    ({title, data, suffix}) =>
                        title &&
                        data && (
                            <div className={cls('sub-div-item')}>
                                <div className={cls('sub-div-item_title')}>
                                    {title}
                                    {suffix && <span className={cls(suffix.class)}>{suffix.name}</span>}
                                </div>
                                {data.map(({href, name, suffix}) => (
                                    <div className={cls('sub-div-item_link')} key={name}>
                                        <Link href={href} trackTag={`顶部导航栏_操作平台_${name}`}>
                                            {name}
                                            {suffix && <span className={cls(suffix.class)}>{suffix.name}</span>}
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        )
                )}
        </div>
    );
};

function Special(props) {
    const {name, type, items, pageName} = props;
    if (type === 'default') {
        return <Default {...props} />;
    }

    if (type === 'intro') {
        return (
            <div className={cls('nav-item')}>
                <a>{name}</a>
                <div className={cls('sub-div')}>
                    {items.map(({title, data}) => (
                        <div className={cls('sub-div-item')} key={title}>
                            <div className={cls('sub-div-item_title')}>{title}</div>
                            {data.map(({href, name, suffix}) => (
                                <div className={cls('sub-div-item_link')} key={name}>
                                    <Link trackTag={`顶部导航栏_产品介绍_${name}`} href={href}>
                                        {name}
                                    </Link>
                                    {suffix && <span className={cls(suffix.class)}>{suffix.name}</span>}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    if (type === 'doc') {
        return (
            <div
                className={classnames(cls('nav-item'), {
                    [css.doc]: pageName === 'easydl'
                })}
            >
                <a>{name}</a>
                <div className={cls('sub-div')}>
                    <div className={cls('sub-div-item')}>
                        {items.map(({href, name, suffix}) => (
                            <div className={cls('sub-div-item_link')} key={name}>
                                <Link trackTag={`顶部导航栏_使用文档_${name}`} href={href}>
                                    {name}
                                </Link>
                                {suffix && <span className={cls(suffix.class)}>{suffix.name}</span>}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={cls('nav-item')}>
            <a>{name}</a>
            <div className={classnames(cls('sub-div'), css.platform)}>
                <div className={cls('platform-content')}>
                    <div className={cls('online')}>
                        <h3 className={cls('platform-title')}>在线产品</h3>
                        <div className={cls('online-content')}>
                            <PlatformItems items={items} filterNum={0} />
                            <PlatformItems items={items} filterNum={1} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function Header(props) {
    const {logo, left, right, app, target, actionConfig, pageName, page, platform, home} = props;

    const showAction = useMemo(() => {
        const DisableList = ['codelab', 'data', 'retail'];
        return !DisableList.includes(page);
    }, [page]);

    const User = platForm2Header[platform];
    const StartButton = useMemo(() => {
        return (
            <div className={cls('nav-item')}>
                {/* paddle 增加特判 */}
                {page === 'paddle' ? (
                    <Link
                        className={classnames(css.immediately)}
                        trackTag="顶部导航栏_立即下载"
                        href="#paddle-download"
                    >
                        立即下载
                    </Link>
                ) : page === 'wenxin' ? (
                    <Link
                        href={actionConfig.target}
                        className={classnames(css.immediately)}
                        trackTag={actionConfig.trackTag}
                    >
                        {actionConfig.text}
                    </Link>
                ) : (
                    <Link
                        href={target}
                        className={classnames(css.immediately, !target && 'js-consoleModalBtn')}
                        trackTag="顶部导航栏_立即使用"
                    >
                        立即使用
                    </Link>
                )}
            </div>
        );
    }, [page, target]);

    const action = app ? <User /> : StartButton;

    return (
        <header className={cls('header', pageName)}>
            {logo ? (
                <Link href={logo.href}>
                    <img className={cls(pageName)} src={logo.src} alt={logo.alt} />
                </Link>
            ) : (
                <Link href={home.href}>
                    <div className={cls(pageName)}>
                        <img src={home.icon} />
                        {home.content}
                    </div>
                </Link>
            )}
            <nav className={css.nav}>
                {left.map((it) => (
                    <Special {...it} key={it.name} pageName={pageName} />
                ))}
                <div className={css.blank} />
                {!!right && right.map((it) => <Default {...it} key={it.name} />)}
                {showAction && action}
            </nav>
        </header>
    );
}
